import React from "react";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIASMALL,
  TRANSCOLOR,
  TRANSCOLORRGBA,
} from "../../../variable";
import { borderRadiusColomMediaQuery } from "../../../helpers/mediaQueryHelper";
import { injectFlex } from "../../../helpers/builderHelper";
const BuilderColom = ({
  row,
  colomIndexs,
  widthDev,
  windowWidth,
  children,
  c,
}) => {
  const visualColom = (row, key, value) => {
    switch (widthDev) {
      case MEDIALARGE:
        return value;
      case MEDIAMEDIUM:
        if (row.mediaQueri768 && row.mediaQueri768[key]) {
          return row.mediaQueri768[key];
        } else {
          return value;
        }
      case MEDIASMALL:
        if (row.mediaQueri360 && row.mediaQueri360[key]) {
          return row.mediaQueri360[key];
        } else {
          if (row.mediaQueri768 && row.mediaQueri768[key]) {
            return row.mediaQueri768[key];
          } else {
            return value;
          }
        }
    }
  };

  const ColomColor = ({ row, c, colomIndexs }) => {
    return (
      <div
        style={{
          ...injectFlex(c, visualColom),
        }}
      >
        <div
          id={`${row.index}-${colomIndexs}`}
          style={{
            position: c.position,
            marginTop: visualColom(c, "marginTop", c.marginTop),
            marginRight: visualColom(c, "marginRight", c.marginRight),
            marginBottom: visualColom(c, "marginBottom", c.marginBottom),
            marginLeft: visualColom(c, "marginLeft", c.marginLeft),
            paddingTop: visualColom(c, "paddingTop", c.paddingTop),
            paddingRight: visualColom(c, "paddingRight", c.paddingRight),
            paddingBottom: visualColom(c, "paddingBottom", c.paddingBottom),
            paddingLeft: visualColom(c, "paddingLeft", c.paddingLeft),
            background:
              c.linear === "solid"
                ? c?.backgroundColor !== TRANSCOLOR
                  ? c?.backgroundColor
                  : "unset"
                : `linear-gradient(to bottom, ${
                    c?.backgroundColor !== TRANSCOLOR
                      ? c?.backgroundColor
                      : TRANSCOLORRGBA
                  } ,${
                    c?.backgroundColor2 !== TRANSCOLOR
                      ? c?.backgroundColor2
                      : TRANSCOLORRGBA
                  })`,
            border: `${visualColom(
              c,
              "borderStyle",
              c.borderStyle
            )} ${visualColom(c, "borderWidth", c.borderWidth)}px ${
              c.borderColor
            }`,
            borderRadius: borderRadiusColomMediaQuery(c, widthDev),
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  const ColomImage = ({ row, c, colomIndexs }) => {
    return (
      <div
        id={`${row.index}-${colomIndexs}`}
        style={{
          ...injectFlex(c, visualColom),
          background: `url(${c.backgroundImage})`,
          backgroundSize: c.backgroundSize,
          backgroundPosition:
            windowWidth > 450 ? "center center" : "center center",
          overflow: "hidden",
          border: `${visualColom(
            c,
            "borderStyle",
            c.borderStyle
          )} ${visualColom(c, "borderWidth", c.borderWidth)}px ${
            c.borderColor
          }`,
          borderRadius: borderRadiusColomMediaQuery(c, widthDev),
        }}
      >
        {c.foreground ? (
          <div
            id={`${row.index}-${colomIndexs}`}
            style={{
              background:
                c.linear === "solid"
                  ? c?.cover?.backgroundColor !== TRANSCOLOR
                    ? c?.cover?.backgroundColor
                    : "unset"
                  : `linear-gradient(to bottom, ${
                      c?.cover?.backgroundColor !== TRANSCOLOR
                        ? c?.cover?.backgroundColor
                        : TRANSCOLORRGBA
                    } ,${
                      c?.cover2?.backgroundColor !== TRANSCOLOR
                        ? c?.cover2?.backgroundColor
                        : TRANSCOLORRGBA
                    }`,
              marginTop: visualColom(c, "marginTop", c.marginTop),
              marginRight: visualColom(c, "marginRight", c.marginRight),
              marginBottom: visualColom(c, "marginBottom", c.marginBottom),
              marginLeft: visualColom(c, "marginLeft", c.marginLeft),
              paddingTop: visualColom(c, "paddingTop", c.paddingTop),
              paddingRight: visualColom(c, "paddingRight", c.paddingRight),
              paddingBottom: visualColom(c, "paddingBottom", c.paddingBottom),
              paddingLeft: visualColom(c, "paddingLeft", c.paddingLeft),
            }}
          >
            {children}
          </div>
        ) : (
          <div
            id={`${row.index}-${colomIndexs}`}
            style={{
              marginTop: visualColom(c, "marginTop", c.marginTop),
              marginRight: visualColom(c, "marginRight", c.marginRight),
              marginBottom: visualColom(c, "marginBottom", c.marginBottom),
              marginLeft: visualColom(c, "marginLeft", c.marginLeft),
              paddingTop: visualColom(c, "paddingTop", c.paddingTop),
              paddingRight: visualColom(c, "paddingRight", c.paddingRight),
              paddingBottom: visualColom(c, "paddingBottom", c.paddingBottom),
              paddingLeft: visualColom(c, "paddingLeft", c.paddingLeft),
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  };

  const ColomSlide = ({ row, c, colomIndexs }) => {
    return (
      <div
        id={`${row.index}-${colomIndexs}`}
        style={{
          ...injectFlex(c, visualColom),
          position: "relative",
          background: c?.image_slide ? `url(${c.image_slide[0].img})` : "",
          backgroundSize: "cover",
          backgroundPosition:
            windowWidth > 450 ? "center center" : "center center",
          overflow: "hidden",
          border: `${visualColom(
            c,
            "borderStyle",
            c.borderStyle
          )} ${visualColom(c, "borderWidth", c.borderWidth)}px ${
            c.borderColor
          }`,
          borderRadius: borderRadiusColomMediaQuery(c, widthDev),
        }}
      >
        {c.foreground ? (
          <div
            id={`${row.index}-${colomIndexs}`}
            style={{
              background:
                c.linear === "solid"
                  ? c?.cover?.backgroundColor !== TRANSCOLOR
                    ? c?.cover?.backgroundColor
                    : "unset"
                  : `linear-gradient(to bottom, ${
                      c?.cover?.backgroundColor !== TRANSCOLOR
                        ? c?.cover?.backgroundColor
                        : TRANSCOLORRGBA
                    } ,${
                      c?.cover2?.backgroundColor !== TRANSCOLOR
                        ? c?.cover2?.backgroundColor
                        : TRANSCOLORRGBA
                    }`,
              marginTop: visualColom(c, "marginTop", c.marginTop),
              marginRight: visualColom(c, "marginRight", c.marginRight),
              marginBottom: visualColom(c, "marginBottom", c.marginBottom),
              marginLeft: visualColom(c, "marginLeft", c.marginLeft),
              paddingTop: visualColom(c, "paddingTop", c.paddingTop),
              paddingRight: visualColom(c, "paddingRight", c.paddingRight),
              paddingBottom: visualColom(c, "paddingBottom", c.paddingBottom),
              paddingLeft: visualColom(c, "paddingLeft", c.paddingLeft),
            }}
          >
            {children}
          </div>
        ) : (
          <div
            id={`${row.index}-${colomIndexs}`}
            style={{
              marginTop: visualColom(c, "marginTop", c.marginTop),
              marginRight: visualColom(c, "marginRight", c.marginRight),
              marginBottom: visualColom(c, "marginBottom", c.marginBottom),
              marginLeft: visualColom(c, "marginLeft", c.marginLeft),
              paddingTop: visualColom(c, "paddingTop", c.paddingTop),
              paddingRight: visualColom(c, "paddingRight", c.paddingRight),
              paddingBottom: visualColom(c, "paddingBottom", c.paddingBottom),
              paddingLeft: visualColom(c, "paddingLeft", c.paddingLeft),
            }}
          >
            {children}
          </div>
        )}
      </div>
    );
  };

  const ColomSection = ({ row, colomIndexs, c }) => {
    if (c.backgroundType === undefined) {
      c.backgroundType = "color";
    }
    switch (c.backgroundType) {
      case "color":
        return (
          <ColomColor row={row} c={c} colomIndexs={colomIndexs}>
            {children}
          </ColomColor>
        );
      case "image":
        return (
          <>
            <ColomImage row={row} c={c} colomIndexs={colomIndexs}>
              {children}
            </ColomImage>
          </>
        );
      case "slide":
        return (
          <ColomSlide row={row} c={c} colomIndexs={colomIndexs}>
            {children}
          </ColomSlide>
        );
      default:
        return (
          <ColomImage row={row} c={c} colomIndexs={colomIndexs}>
            {children}
          </ColomImage>
        );
    }
  };

  return (
    <ColomSection row={row} colomIndexs={colomIndexs} c={c}></ColomSection>
  );
};

export default BuilderColom;
