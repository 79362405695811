import React, { useState, useCallback } from "react";
import {
  Image,
  Navbar,
  Nav,
  Container,
  Carousel,
  Row,
  Col,
} from "react-bootstrap";
import FormLPComp from "../../form/FormLPComp";
import IconButtonComp from "../IconButtonComp";
import ImageViewer from "react-simple-image-viewer";
import Masonry from "react-masonry-css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "react-image-gallery";
import { getActionId, saveActionId } from "../../../helpers/storageHelper";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIASMALL,
  TRANSCOLOR,
  TRANSCOLORRGBA,
} from "../../../variable";
import mediaQuery, {
  borderRadiusMediaQuery,
} from "../../../helpers/mediaQueryHelper";
const LandingpageColom = ({
  idview,
  viewState,
  rowIndex,
  colom,
  colomCB,
  colomRemoveCB,
  widthDev,
  showSlide,
  showColMenu,
}) => {
  console.log("colomdsfsd", colom);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [];
  const transformGallery = (image) => {
    let data = [];
    if (image.length > 0) {
      for (let a = 0; a < image.length; a++) {
        data.push({
          original: image[a].img,
          thumbnail: image[a].img,
        });
      }
    } else {
      data.push({
        original: "",
        thumbnail: "",
      });
    }
    return data;
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const masonryBreakPoint = () => {
    if (widthDev === "100%") {
      return 3;
    }
    if (widthDev === "768px") {
      return 2;
    }
    if (widthDev === "360px") {
      return 1;
    }
  };

  const ActionButton = ({ selectedContent, withClass, id }) => {
    let hasClass = withClass !== undefined ? withClass : true;
    return (
      <div
        id={`actionbutton-${id}`}
        style={{
          display: getActionId() === `actionbutton-${id}` ? "flex" : "none",
          width: "80px",
          justifyContent: "space-between",
          width: "100px",
          background: "",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <div style={{ flex: 1 }}>
          <IconButtonComp
            background="#333"
            action="update"
            icon="fa-pen"
            cb={IconAction}
            selectedContent={selectedContent}
            id={id}
          ></IconButtonComp>
        </div>
        <div style={{ flex: 1 }}>
          <IconButtonComp
            background="red"
            action="remove"
            icon="fa-trash"
            cb={IconAction}
            selectedContent={selectedContent}
            id={id}
          ></IconButtonComp>
        </div>
      </div>
    );
  };

  const changeItem = (selectedContent) => {
    colom.id = selectedContent.id;
    colomCB(colom);
  };

  const removeItem = (selectedContent) => {
    colom.id = selectedContent.id;
    colomRemoveCB(colom);
  };

  const opts = {
    height: "450px",
    width:
      "100%                                                                                                                                                                                                               ",
    playerVars: {
      autoplay: 0,
    },
  };

  const ListTags = (content, id) => {
    var list = content.content.split("-");
    var kudu = [];
    for (let a = 0; a < list.length; a++) {
      if (a > 0) {
        kudu.push(
          <li
            id={id}
            style={{
              textAlign: "left",
              listStyle: content.icon && "none",
              fontSize: content.fontSize,
              color: content.color,
            }}
          >
            <i
              className={content.icon}
              style={{
                fontSize: content.fontSize,
                color: content.color,
                marginRight: "10px",
              }}
            ></i>{" "}
            {list[a]}
          </li>
        );
      }
    }
    return kudu;
  };

  const toggleAction = async (id) => {
    let actionId = await getActionId();
    if (actionId) {
      if (document.getElementById(actionId)) {
        document.getElementById(actionId).style.display = "none";
      }
    }
    setTimeout(() => {
      let actionIdNow = `actionbutton-${id}`;
      if (document.getElementById(actionIdNow)) {
        document.getElementById(actionIdNow).style.display = "flex";
        saveActionId(actionIdNow);
      }
    }, 1000);
  };

  const switchType = (content, id) => {
    if (content?.type) {
      if (content.type === "Countdown") {
        if (content.duedate !== "") {
          // setCountDownConfig(content)
        }
      }
      switch (content.type) {
        case viewState.viewVideo:
          return (
            <div
              id={id}
              className="lpcomponent-box"
              style={{
                marginTop: mediaQuery(
                  content,
                  "marginTop",
                  content.marginTop,
                  widthDev
                ),
                marginRight: mediaQuery(
                  content,
                  "marginRight",
                  content.marginRight,
                  widthDev
                ),
                marginBottom: mediaQuery(
                  content,
                  "marginBottom",
                  content.marginBottom,
                  widthDev
                ),
                marginLeft: mediaQuery(
                  content,
                  "marginLeft",
                  content.marginLeft,
                  widthDev
                ),
                paddingTop: mediaQuery(
                  content,
                  "paddingTop",
                  content.paddingTop,
                  widthDev
                ),
                paddingRight: mediaQuery(
                  content,
                  "paddingRight",
                  content.paddingRight,
                  widthDev
                ),
                paddingBottom: mediaQuery(
                  content,
                  "paddingBottom",
                  content.paddingBottom,
                  widthDev
                ),
                paddingLeft: mediaQuery(
                  content,
                  "paddingLeft",
                  content.paddingLeft,
                  widthDev
                ),
                textAlign: mediaQuery(
                  content,
                  "textAlign",
                  content.textAlign,
                  widthDev
                ),
              }}
              onClick={() => toggleAction(id)}
            >
              <ActionButton selectedContent={content} id={id}></ActionButton>

              <img
                style={{ overflow: "auto" }}
                id={id}
                src="https://d1jcw7e5n1q2pt.cloudfront.net/1701999320544-yt-removebg-preview.png"
                width="100%"
                height="450"
              />
            </div>
          );
          break;
        case viewState.viewImage:
          return (
            <div onClick={() => toggleAction(id)}>
              {content.position === "absolute" ? (
                <>
                  <div
                    id={id}
                    style={{
                      textAlign: mediaQuery(
                        content,
                        "textAlign",
                        content.textAlign,
                        widthDev
                      ),
                    }}
                  >
                    <div id={id} style={{ overflow: "auto" }}>
                      <div
                        id={id}
                        style={{
                          width: "fit-content",
                          float:
                            content.textAlign === "center"
                              ? ""
                              : content.textAlign,
                          margin:
                            content.textAlign === "center" ? "0 auto" : "",
                        }}
                      >
                        <div>
                          <Image
                            className={`lpcomponent-box ${content.shape} rotate${content?.rotate}`}
                            id={id}
                            src={content.image}
                            fluid
                            style={{
                              transform: `rotate(${mediaQuery(
                                content,
                                "rotate",
                                content.rotate,
                                widthDev
                              )}deg)`,
                              borderRadius: borderRadiusMediaQuery(
                                content,
                                "borderRadius",
                                {
                                  borderRadius: content.borderRadius,
                                  borderTopLeftRadius:
                                    content.borderTopLeftRadius,
                                  borderTopRightRadius:
                                    content.borderTopRightRadius,
                                  borderBottomLeftRadius:
                                    content.borderBottomLeftRadius,
                                  borderBottomRightRadius:
                                    content.borderBottomRightRadius,
                                },
                                widthDev
                              ),
                              // borderRadius: mediaQuery(content, 'borderRadius', content.borderRadius, widthDev),
                              width: mediaQuery(
                                content,
                                "width",
                                content.width,
                                widthDev
                              ),
                              height: mediaQuery(
                                content,
                                "height",
                                content.height,
                                widthDev
                              ),
                              position: "absolute",
                              top: `${mediaQuery(
                                content,
                                "positionAbsolute.top",
                                content.positionAbsolute.top,
                                widthDev
                              )}%`,
                              right: `${mediaQuery(
                                content,
                                "positionAbsolute.right",
                                content.positionAbsolute.right,
                                widthDev
                              )}%`,
                              bottom: `${mediaQuery(
                                content,
                                "positionAbsolute.bottom",
                                content.positionAbsolute.bottom,
                                widthDev
                              )}%`,
                              left: `${mediaQuery(
                                content,
                                "positionAbsolute.left",
                                content.positionAbsolute.left,
                                widthDev
                              )}%`,
                              pointerEvents:
                                content.position === "absolute" ? "none" : "",
                              marginTop: mediaQuery(
                                content,
                                "marginTop",
                                content.marginTop,
                                widthDev
                              ),
                              marginRight: mediaQuery(
                                content,
                                "marginRight",
                                content.marginRight,
                                widthDev
                              ),
                              marginBottom: mediaQuery(
                                content,
                                "marginBottom",
                                content.marginBottom,
                                widthDev
                              ),
                              marginLeft: mediaQuery(
                                content,
                                "marginLeft",
                                content.marginLeft,
                                widthDev
                              ),
                              paddingTop: mediaQuery(
                                content,
                                "paddingTop",
                                content.paddingTop,
                                widthDev
                              ),
                              paddingRight: mediaQuery(
                                content,
                                "paddingRight",
                                content.paddingRight,
                                widthDev
                              ),
                              paddingBottom: mediaQuery(
                                content,
                                "paddingBottom",
                                content.paddingBottom,
                                widthDev
                              ),
                              paddingLeft: mediaQuery(
                                content,
                                "paddingLeft",
                                content.paddingLeft,
                                widthDev
                              ),
                              borderStyle: mediaQuery(
                                content,
                                "borderStyle",
                                content.borderStyle,
                                widthDev
                              ),
                              borderWidth: mediaQuery(
                                content,
                                "borderWidth",
                                content.borderWidth,
                                widthDev
                              ),
                              borderColor: mediaQuery(
                                content,
                                "borderColor",
                                content.borderColor,
                                widthDev
                              ),
                              zIndex:
                                mediaQuery(
                                  content,
                                  "zIndex",
                                  content.zIndex,
                                  widthDev
                                ) > 0
                                  ? mediaQuery(
                                      content,
                                      "zIndex",
                                      content.zIndex,
                                      widthDev
                                    )
                                  : 1,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    id={id}
                    style={{
                      textAlign: mediaQuery(
                        content,
                        "textAlign",
                        content.textAlign,
                        widthDev
                      ),
                    }}
                  >
                    <div
                      id={id}
                      style={{ overflow: "auto", position: "relative" }}
                    >
                      <div
                        style={{
                          width: "fit-content",
                          position: "relative",
                          margin:
                            content.textAlign === "center" ? "0 auto" : "0",
                          float:
                            content.textAlign === "center"
                              ? ""
                              : content.textAlign,
                        }}
                      >
                        <div
                          id={id}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            zIndex: "99",
                          }}
                        >
                          <ActionButton
                            selectedContent={content}
                            withClass={false}
                            id={id}
                          ></ActionButton>
                        </div>
                        <div>
                          <Image
                            className={`lpcomponent-box ${content.shape} rotate${content?.rotate}`}
                            id={id}
                            src={content.image}
                            fluid
                            style={{
                              transform: `rotate(${mediaQuery(
                                content,
                                "rotate",
                                content.rotate,
                                widthDev
                              )}deg)`,
                              borderRadius: borderRadiusMediaQuery(
                                content,
                                "borderRadius",
                                {
                                  borderRadius: content.borderRadius,
                                  borderTopLeftRadius:
                                    content.borderTopLeftRadius,
                                  borderTopRightRadius:
                                    content.borderTopRightRadius,
                                  borderBottomLeftRadius:
                                    content.borderBottomLeftRadius,
                                  borderBottomRightRadius:
                                    content.borderBottomRightRadius,
                                },
                                widthDev
                              ),
                              borderStyle: mediaQuery(
                                content,
                                "borderStyle",
                                content.borderStyle,
                                widthDev
                              ),
                              borderWidth: mediaQuery(
                                content,
                                "borderWidth",
                                content.borderWidth,
                                widthDev
                              ),
                              borderColor: mediaQuery(
                                content,
                                "borderColor",
                                content.borderColor,
                                widthDev
                              ),
                              height: mediaQuery(
                                content,
                                "height",
                                content.height,
                                widthDev
                              ),
                              width: mediaQuery(
                                content,
                                "width",
                                content.width,
                                widthDev
                              ),
                              pointerEvents:
                                content.position === "absolute" ? "none" : "",
                              marginTop: mediaQuery(
                                content,
                                "marginTop",
                                content.marginTop,
                                widthDev
                              ),
                              marginRight: mediaQuery(
                                content,
                                "marginRight",
                                content.marginRight,
                                widthDev
                              ),
                              marginBottom: mediaQuery(
                                content,
                                "marginBottom",
                                content.marginBottom,
                                widthDev
                              ),
                              marginLeft: mediaQuery(
                                content,
                                "marginLeft",
                                content.marginLeft,
                                widthDev
                              ),
                              paddingTop: mediaQuery(
                                content,
                                "paddingTop",
                                content.paddingTop,
                                widthDev
                              ),
                              paddingRight: mediaQuery(
                                content,
                                "paddingRight",
                                content.paddingRight,
                                widthDev
                              ),
                              paddingBottom: mediaQuery(
                                content,
                                "paddingBottom",
                                content.paddingBottom,
                                widthDev
                              ),
                              paddingLeft: mediaQuery(
                                content,
                                "paddingLeft",
                                content.paddingLeft,
                                widthDev
                              ),
                              zIndex: mediaQuery(
                                content,
                                "zIndex",
                                content.zIndex,
                                widthDev
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
          // }

          break;

        case viewState.viewImageSlider:
          return (
            <>
              <div
                id={id}
                style={{ overflow: "auto", display: "flex", width: widthDev }}
              >
                <div
                  className="lpcomponent-box"
                  style={{ flex: 1 }}
                  onClick={() => toggleAction(id)}
                >
                  <div
                    id={id}
                    style={{
                      marginTop: mediaQuery(
                        content,
                        "marginTop",
                        content.marginTop,
                        widthDev
                      ),
                      marginRight: mediaQuery(
                        content,
                        "marginRight",
                        content.marginRight,
                        widthDev
                      ),
                      marginBottom: mediaQuery(
                        content,
                        "marginBottom",
                        content.marginBottom,
                        widthDev
                      ),
                      marginLeft: mediaQuery(
                        content,
                        "marginLeft",
                        content.marginLeft,
                        widthDev
                      ),
                      paddingTop: mediaQuery(
                        content,
                        "paddingTop",
                        content.paddingTop,
                        widthDev
                      ),
                      paddingRight: mediaQuery(
                        content,
                        "paddingRight",
                        content.paddingRight,
                        widthDev
                      ),
                      paddingBottom: mediaQuery(
                        content,
                        "paddingBottom",
                        content.paddingBottom,
                        widthDev
                      ),
                      paddingLeft: mediaQuery(
                        content,
                        "paddingLeft",
                        content.paddingLeft,
                        widthDev
                      ),
                    }}
                  >
                    <Masonry
                      breakpointCols={masonryBreakPoint()}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                      id={id}
                    >
                      {content.image &&
                        content.image.map((s, index) => {
                          images.push(s.img);
                          return (
                            <figure onClick={() => openImageViewer(index)}>
                              <img
                                src={s.img}
                                alt=""
                                className="img-fluid"
                                id={id}
                              />
                            </figure>
                          );
                        })}
                    </Masonry>
                    {isViewerOpen && (
                      <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                      />
                    )}
                  </div>
                  <ActionButton
                    selectedContent={content}
                    id={id}
                  ></ActionButton>
                </div>
              </div>
            </>
          );
          break;

        case viewState.viewImageGallery:
          return (
            <>
              {content.image.length > 0 && (
                <div
                  id={id}
                  style={{ overflow: "auto", display: "flex", width: widthDev }}
                >
                  <div
                    className="lpcomponent-box"
                    style={{ flex: 1 }}
                    onClick={() => toggleAction(id)}
                  >
                    <div
                      id={id}
                      style={{
                        marginTop: mediaQuery(
                          content,
                          "marginTop",
                          content.marginTop,
                          widthDev
                        ),
                        marginRight: mediaQuery(
                          content,
                          "marginRight",
                          content.marginRight,
                          widthDev
                        ),
                        marginBottom: mediaQuery(
                          content,
                          "marginBottom",
                          content.marginBottom,
                          widthDev
                        ),
                        marginLeft: mediaQuery(
                          content,
                          "marginLeft",
                          content.marginLeft,
                          widthDev
                        ),
                        paddingTop: mediaQuery(
                          content,
                          "paddingTop",
                          content.paddingTop,
                          widthDev
                        ),
                        paddingRight: mediaQuery(
                          content,
                          "paddingRight",
                          content.paddingRight,
                          widthDev
                        ),
                        paddingBottom: mediaQuery(
                          content,
                          "paddingBottom",
                          content.paddingBottom,
                          widthDev
                        ),
                        paddingLeft: mediaQuery(
                          content,
                          "paddingLeft",
                          content.paddingLeft,
                          widthDev
                        ),
                        textAlign: mediaQuery(
                          content,
                          "textAlign",
                          content.textAlign,
                          widthDev
                        ),
                      }}
                    >
                      <ImageGallery
                        id={id}
                        items={transformGallery(content.image)}
                      />
                      <ActionButton
                        selectedContent={content}
                        id={id}
                      ></ActionButton>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
          break;
        case viewState.viewIcon:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <div
                id={id}
                style={{
                  textAlign: mediaQuery(
                    content,
                    "textAlign",
                    content.textAlign,
                    widthDev
                  ),
                  marginTop: mediaQuery(
                    content,
                    "marginTop",
                    content.marginTop,
                    widthDev
                  ),
                  marginRight: mediaQuery(
                    content,
                    "marginRight",
                    content.marginRight,
                    widthDev
                  ),
                  marginBottom: mediaQuery(
                    content,
                    "marginBottom",
                    content.marginBottom,
                    widthDev
                  ),
                  marginLeft: mediaQuery(
                    content,
                    "marginLeft",
                    content.marginLeft,
                    widthDev
                  ),
                  paddingTop: mediaQuery(
                    content,
                    "paddingTop",
                    content.paddingTop,
                    widthDev
                  ),
                  paddingRight: mediaQuery(
                    content,
                    "paddingRight",
                    content.paddingRight,
                    widthDev
                  ),
                  paddingBottom: mediaQuery(
                    content,
                    "paddingBottom",
                    content.paddingBottom,
                    widthDev
                  ),
                  paddingLeft: mediaQuery(
                    content,
                    "paddingLeft",
                    content.paddingLeft,
                    widthDev
                  ),
                  color: mediaQuery(content, "color", content.color, widthDev),
                  backgroundColor: mediaQuery(
                    content,
                    "backgroundColor",
                    content.backgroundColor,
                    widthDev
                  ),
                }}
              >
                <i
                  id={id}
                  className={content.icon}
                  style={{ fontSize: content.fontSize, color: content.color }}
                ></i>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewSpace:
          return (
            <div
              id={id}
              style={{ overflow: "auto" }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <div
                id={id}
                style={{
                  marginTop: mediaQuery(
                    content,
                    "marginTop",
                    content.marginTop,
                    widthDev
                  ),
                }}
              >
                <div style={{ width: "30px", height: "10px" }}></div>
                <i
                  id={id}
                  className={content.icon}
                  style={{ fontSize: "20px", color: content.color }}
                ></i>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewLine:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                textAlign: content.textAlign,
                marginTop: content.marginTop,
                marginRight: content.marginRight,
                marginBottom: content.marginBottom,
                marginLeft: content.marginLeft,
                paddingTop: content.paddingTop,
                paddingRight: content.paddingRight,
                paddingBottom: content.paddingBottom,
                paddingLeft: content.paddingLeft,
                backgroundColor: content.backgroundColor,
                color: content.color,
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <div id={id} style={{ textAlign: content.textAlign }}>
                <div
                  style={{
                    border: `${content.borderType} ${content.border} ${content.borderColor}`,
                  }}
                ></div>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewTimeline:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                marginTop: mediaQuery(
                  content,
                  "marginTop",
                  content.marginTop,
                  widthDev
                ),
                marginRight: mediaQuery(
                  content,
                  "marginRight",
                  content.marginRight,
                  widthDev
                ),
                marginBottom: mediaQuery(
                  content,
                  "marginBottom",
                  content.marginBottom,
                  widthDev
                ),
                marginLeft: mediaQuery(
                  content,
                  "marginLeft",
                  content.marginLeft,
                  widthDev
                ),
                paddingTop: mediaQuery(
                  content,
                  "paddingTop",
                  content.paddingTop,
                  widthDev
                ),
                paddingRight: mediaQuery(
                  content,
                  "paddingRight",
                  content.paddingRight,
                  widthDev
                ),
                paddingBottom: mediaQuery(
                  content,
                  "paddingBottom",
                  content.paddingBottom,
                  widthDev
                ),
                paddingLeft: mediaQuery(
                  content,
                  "paddingLeft",
                  content.paddingLeft,
                  widthDev
                ),
              }}
              className="lpcomponent-box"
            >
              <div id={id} onClick={() => toggleAction(id)}>
                <VerticalTimeline
                  id={id}
                  animate={false}
                  lineColor={content.backgroundColor}
                  layout={widthDev === "100%" ? "2-columns" : "1-column-left"}
                >
                  {content.content.map((c) => {
                    return (
                      <VerticalTimelineElement
                        id={id}
                        icon={
                          <FontAwesomeIcon
                            icon={faHeart}
                            color={content.color}
                          />
                        }
                        contentStyle={{
                          background: content.backgroundColor,
                          color: content.color,
                        }}
                        iconStyle={{
                          background: content.backgroundColor,
                          color: content.color,
                        }}
                        contentArrowStyle={{
                          borderRight: `7px solid  ${content.backgroundColor}`,
                        }}
                        date={c.year}
                      >
                        <h5
                          id={id}
                          className="vertical-timeline-element-title"
                          style={{ color: content.color }}
                        >
                          {c.title}
                        </h5>
                        <p id={id} style={{ color: content.color }}>
                          {c.desc}
                        </p>
                      </VerticalTimelineElement>
                    );
                  })}
                </VerticalTimeline>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewNavbar:
          return (
            <div
              id={id}
              style={{
                textAlign: content.textAlign,
                marginTop: content.marginTop,
                marginRight: content.marginRight,
                marginBottom: content.marginBottom,
                marginLeft: content.marginLeft,
                paddingTop: content.paddingTop,
                paddingRight: content.paddingRight,
                paddingBottom: content.paddingBottom,
                paddingLeft: content.paddingLeft,
                backgroundColor: content.backgroundColor,
                color: content.color,
              }}
              className="lpcomponent-box"
            >
              <div id={id} style={{ textAlign: content.textAlign }}>
                <Navbar bg="light" variant="light">
                  <Container>
                    <Navbar.Brand href="#home">Navbar</Navbar.Brand>
                    <Nav className="me-auto">
                      <Nav.Link href="#home">Home</Nav.Link>
                      <Nav.Link href="#features">Features</Nav.Link>
                      <Nav.Link href="#pricing">Pricing</Nav.Link>
                    </Nav>
                  </Container>
                </Navbar>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewCarousel:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                textAlign: content.textAlign,
                marginTop: content.marginTop,
                marginRight: content.marginRight,
                marginBottom: content.marginBottom,
                marginLeft: content.marginLeft,
                paddingTop: content.paddingTop,
                paddingRight: content.paddingRight,
                paddingBottom: content.paddingBottom,
                paddingLeft: content.paddingLeft,
                backgroundColor: content.backgroundColor,
                color: content.color,
              }}
              className="lpcomponent-box"
            >
              <div id={id} style={{ textAlign: content.textAlign }}>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="https://images.unsplash.com/photo-1465495976277-4387d4b0b4c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <h3>Second slide label</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="https://images.unsplash.com/photo-1545232979-8bf68ee9b1af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                      alt="Third slide"
                    />

                    <Carousel.Caption>
                      <h3>Third slide label</h3>
                      <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewList:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                width: "100%",
                background: content.background,
                borderRadius: borderRadiusMediaQuery(
                  content,
                  "borderRadius",
                  {
                    borderRadius: content.borderRadius,
                    borderTopLeftRadius: content.borderTopLeftRadius,
                    borderTopRightRadius: content.borderTopRightRadius,
                    borderBottomLeftRadius: content.borderBottomLeftRadius,
                    borderBottomRightRadius: content.borderBottomRightRadius,
                  },
                  widthDev
                ),
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <ul
                style={{
                  display: "inline-block",
                  textAlign: mediaQuery(
                    content,
                    "textAlign",
                    content.textAlign,
                    widthDev
                  ),
                  marginTop: mediaQuery(
                    content,
                    "marginTop",
                    content.marginTop,
                    widthDev
                  ),
                  marginRight: mediaQuery(
                    content,
                    "marginRight",
                    content.marginRight,
                    widthDev
                  ),
                  marginBottom: mediaQuery(
                    content,
                    "marginBottom",
                    content.marginBottom,
                    widthDev
                  ),
                  marginLeft: mediaQuery(
                    content,
                    "marginLeft",
                    content.marginLeft,
                    widthDev
                  ),
                  paddingTop: mediaQuery(
                    content,
                    "paddingTop",
                    content.paddingTop,
                    widthDev
                  ),
                  paddingRight: mediaQuery(
                    content,
                    "paddingRight",
                    content.paddingRight,
                    widthDev
                  ),
                  paddingBottom: mediaQuery(
                    content,
                    "paddingBottom",
                    content.paddingBottom,
                    widthDev
                  ),
                  paddingLeft: mediaQuery(
                    content,
                    "paddingLeft",
                    content.paddingLeft,
                    widthDev
                  ),
                  color: mediaQuery(content, "color", content.color, widthDev),
                }}
              >
                {ListTags(content, id)}
              </ul>
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewBank:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                border: `${content.borderType} ${content.border} ${content.borderColor}`,
                textAlign: mediaQuery(
                  content,
                  "textAlign",
                  content.textAlign,
                  widthDev
                ),
                marginTop: mediaQuery(
                  content,
                  "marginTop",
                  content.marginTop,
                  widthDev
                ),
                marginRight: "auto",
                marginBottom: mediaQuery(
                  content,
                  "marginBottom",
                  content.marginBottom,
                  widthDev
                ),
                marginLeft: "auto",
                paddingTop: mediaQuery(
                  content,
                  "paddingTop",
                  content.paddingTop,
                  widthDev
                ),
                paddingRight: mediaQuery(
                  content,
                  "paddingRight",
                  content.paddingRight,
                  widthDev
                ),
                paddingBottom: mediaQuery(
                  content,
                  "paddingBottom",
                  content.paddingBottom,
                  widthDev
                ),
                paddingLeft: mediaQuery(
                  content,
                  "paddingLeft",
                  content.paddingLeft,
                  widthDev
                ),
                color: mediaQuery(content, "color", content.color, widthDev),
                borderRadius: borderRadiusMediaQuery(
                  content,
                  "borderRadius",
                  {
                    borderRadius: content.borderRadius,
                    borderTopLeftRadius: content.borderTopLeftRadius,
                    borderTopRightRadius: content.borderTopRightRadius,
                    borderBottomLeftRadius: content.borderBottomLeftRadius,
                    borderBottomRightRadius: content.borderBottomRightRadius,
                  },
                  widthDev
                ),
                backgroundColor: mediaQuery(
                  content,
                  "backgroundColor",
                  content.backgroundColor,
                  widthDev
                ),
                width: "300px",
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              {/* {content.bank} */}
              {
                <div
                  id={id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "30px",
                  }}
                >
                  <div id={id} style={{ flex: 1 }}>
                    {" "}
                    <img id={id} src={content.image} width="130px" />
                  </div>
                  <div id={id} style={{ flex: 1 }}>
                    {" "}
                    <p
                      id={id}
                      style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: mediaQuery(
                          content,
                          "color",
                          content.color,
                          widthDev
                        ),
                      }}
                    >
                      {content.account}
                      <div
                        onClick={() =>
                          navigator.clipboard.writeText(content.account)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Salin{" "}
                        <FontAwesomeIcon
                          icon={faCopy}
                          color={mediaQuery(
                            content,
                            "color",
                            content.color,
                            widthDev
                          )}
                        />
                      </div>
                    </p>
                  </div>
                  <div
                    id={id}
                    style={{
                      flex: 1,
                      marginTop: "2px",
                      padding: "0px",
                      fontWeight: "bold",
                      marginTop: "-10px",
                      paddingBottom: "10px",
                      color: mediaQuery(
                        content,
                        "color",
                        content.color,
                        widthDev
                      ),
                    }}
                  >
                    {content.account_name}
                  </div>
                </div>
              }
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewGift:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                border: `${content.borderType} ${content.border} ${content.borderColor}`,
                textAlign: mediaQuery(
                  content,
                  "textAlign",
                  content.textAlign,
                  widthDev
                ),
                marginTop: mediaQuery(
                  content,
                  "marginTop",
                  content.marginTop,
                  widthDev
                ),
                marginRight: "auto",
                marginBottom: mediaQuery(
                  content,
                  "marginBottom",
                  content.marginBottom,
                  widthDev
                ),
                marginLeft: "auto",
                paddingTop: mediaQuery(
                  content,
                  "paddingTop",
                  content.paddingTop,
                  widthDev
                ),
                paddingRight: mediaQuery(
                  content,
                  "paddingRight",
                  content.paddingRight,
                  widthDev
                ),
                paddingBottom: mediaQuery(
                  content,
                  "paddingBottom",
                  content.paddingBottom,
                  widthDev
                ),
                paddingLeft: mediaQuery(
                  content,
                  "paddingLeft",
                  content.paddingLeft,
                  widthDev
                ),
                color: mediaQuery(content, "color", content.color, widthDev),
                borderRadius: borderRadiusMediaQuery(
                  content,
                  "borderRadius",
                  {
                    borderRadius: content.borderRadius,
                    borderTopLeftRadius: content.borderTopLeftRadius,
                    borderTopRightRadius: content.borderTopRightRadius,
                    borderBottomLeftRadius: content.borderBottomLeftRadius,
                    borderBottomRightRadius: content.borderBottomRightRadius,
                  },
                  widthDev
                ),
                backgroundColor: mediaQuery(
                  content,
                  "backgroundColor",
                  content.backgroundColor,
                  widthDev
                ),
                width: "300px",
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              {/* {content.bank} */}
              {
                <div
                  id={id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: "30px",
                  }}
                >
                  <div id={id} style={{ flex: 1 }}>
                    <FontAwesomeIcon
                      icon={faGift}
                      style={{
                        fontSize: "50px",
                        color: mediaQuery(
                          content,
                          "color",
                          content.color,
                          widthDev
                        ),
                      }}
                    />
                  </div>
                  <div id={id} style={{ flex: 1 }}>
                    {" "}
                    <p
                      id={id}
                      style={{
                        marginTop: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: mediaQuery(
                          content,
                          "color",
                          content.color,
                          widthDev
                        ),
                      }}
                    >
                      {content.receiver_name}{" "}
                    </p>
                  </div>
                  <div
                    id={id}
                    style={{
                      flex: 1,
                      marginTop: "2px",
                      padding: "0px",
                      fontWeight: "bold",
                      marginTop: "-10px",
                      paddingBottom: "10px",
                      color: mediaQuery(
                        content,
                        "color",
                        content.color,
                        widthDev
                      ),
                    }}
                  >
                    {content.address}
                  </div>
                </div>
              }
              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );
          break;
        case viewState.viewDevider:
          return (
            <div style={{ overflow: "auto" }}>
              <div
                className="custom-shape-divider"
                id={id}
                style={{
                  overflow: "hidden",
                  transform:
                    content.invert === "true"
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  [content.position]: 0,
                }}
                onClick={() => toggleAction(id)}
              >
                {content.shape === "waves" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "waves opacity" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                      opacity=".25"
                      style={{ fill: content.color }}
                    ></path>
                    <path
                      id={id}
                      d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                      opacity=".5"
                      style={{ fill: content.color }}
                    ></path>
                    <path
                      id={id}
                      d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "curve" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "curve asymmetrical" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M741,116.23C291,117.43,0,27.57,0,6V120H1200V6C1200,27.93,1186.4,119.83,741,116.23Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "triangle" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "triangle asymmetrical" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "tilt" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "arrow" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M649.97 0L599.91 54.12 550.03 0 0 0 0 120 1200 120 1200 0 649.97 0z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "split" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M600,16.8c0-8.11-8.88-13.2-19.92-13.2H0V120H1200V3.6H619.92C608.88,3.6,600,8.66,600,16.8Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
                {content.shape === "book" && (
                  <svg
                    id={id}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    style={{
                      transform:
                        content.flip === "true"
                          ? "rotateY(180deg)"
                          : "rotateY(0)",
                    }}
                  >
                    <path
                      id={id}
                      d="M602.45,3.86h0S572.9,116.24,281.94,120H923C632,116.24,602.45,3.86,602.45,3.86Z"
                      style={{ fill: content.color }}
                    ></path>
                  </svg>
                )}
              </div>
              <div style={{ zIndex: "999" }}>
                <div
                  style={{
                    border: `${content.borderType} ${content.border} ${content.borderColor}`,
                    borderRadius: content.borderRadius,
                    textAlign: "center",
                    background: content.backgroundColor,
                    padding: "10px 5px",
                    width: "300px",
                    margin: "30px auto",
                  }}
                  className="lpcomponent-box"
                >
                  <ActionButton
                    selectedContent={content}
                    id={id}
                  ></ActionButton>
                </div>
              </div>
            </div>
          );
          break;
        case viewState.viewText:
          return (
            <div
              id={id}
              style={{
                textAlign: visualColom(content, "textAlign", content.textAlign),
                overflow: "auto",
                overflowY: "hidden",
              }}
              onClick={() => toggleAction(id)}
            >
              {/* <div style={{ flex: 1 }}> */}
              <div
                id={id}
                style={{ display: "inline-block" }}
                className="lpcomponent-box "
              >
                <div
                  style={{
                    border: `${content.borderType} ${content.border}px ${content.borderColor}`,
                  }}
                >
                  {fontTags(content, id)}
                </div>

                <ActionButton selectedContent={content} id={id}></ActionButton>
              </div>
              {/* </div> */}
            </div>
          );
          break;
        case viewState.viewForm:
          return (
            <div
              id={id}
              style={{ overflow: "auto", display: "flex" }}
              className={
                widthDev === "100%"
                  ? "formWrapper"
                  : widthDev === "768px"
                  ? "formWrapper768"
                  : "formWrapper360"
              }
            >
              <div id={id} className="lpcomponent-box" style={{ flex: 1 }}>
                <ActionButton selectedContent={content} id={id}></ActionButton>
                <div
                  id={id}
                  onClick={() => toggleAction(id)}
                  style={{
                    marginTop: mediaQuery(
                      content,
                      "marginTop",
                      content.marginTop,
                      widthDev
                    ),
                    marginRight: mediaQuery(
                      content,
                      "marginRight",
                      content.marginRight,
                      widthDev
                    ),
                    marginBottom: mediaQuery(
                      content,
                      "marginBottom",
                      content.marginBottom,
                      widthDev
                    ),
                    marginLeft: mediaQuery(
                      content,
                      "marginLeft",
                      content.marginLeft,
                      widthDev
                    ),
                  }}
                >
                  {
                    <FormLPComp
                      id={id}
                      formData={content}
                      widthDev={widthDev}
                    ></FormLPComp>
                  }
                </div>
              </div>
            </div>
          );
          break;
        case viewState.viewCode:
          return (
            <div
              id={id}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <ActionButton selectedContent={content} id={id}></ActionButton>

              <div id={id} dangerouslySetInnerHTML={{ __html: content.html }} />
            </div>
          );
          break;
        case viewState.viewButton:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                textAlign: mediaQuery(
                  content,
                  "textAlign",
                  content.textAlign,
                  widthDev
                ),
              }}
            >
              <div
                id={id}
                style={{ display: "inline-block" }}
                className="lpcomponent-box"
              >
                <ActionButton selectedContent={content} id={id}></ActionButton>
                <div
                  id={id}
                  onClick={() => toggleAction(id)}
                  className="btn"
                  variant=""
                  style={{
                    textTransform: "none",
                    fontSize: mediaQuery(
                      content,
                      "fontSize",
                      content.fontSize,
                      widthDev
                    ),
                    marginTop: mediaQuery(
                      content,
                      "marginTop",
                      content.marginTop,
                      widthDev
                    ),
                    marginRight: mediaQuery(
                      content,
                      "marginRight",
                      content.marginRight,
                      widthDev
                    ),
                    marginBottom: mediaQuery(
                      content,
                      "marginBottom",
                      content.marginBottom,
                      widthDev
                    ),
                    marginLeft: mediaQuery(
                      content,
                      "marginLeft",
                      content.marginLeft,
                      widthDev
                    ),
                    paddingTop: mediaQuery(
                      content,
                      "paddingTop",
                      content.paddingTop,
                      widthDev
                    ),
                    paddingRight: mediaQuery(
                      content,
                      "paddingRight",
                      content.paddingRight,
                      widthDev
                    ),
                    paddingBottom: mediaQuery(
                      content,
                      "paddingBottom",
                      content.paddingBottom,
                      widthDev
                    ),
                    paddingLeft: mediaQuery(
                      content,
                      "paddingLeft",
                      content.paddingLeft,
                      widthDev
                    ),
                    background:
                      content.backgroundColor === TRANSCOLOR
                        ? TRANSCOLORRGBA
                        : content.backgroundColor,
                    color: mediaQuery(
                      content,
                      "color",
                      content.color,
                      widthDev
                    ),
                    borderRadius: borderRadiusMediaQuery(
                      content,
                      "borderRadius",
                      {
                        borderRadius: content.borderRadius,
                        borderTopLeftRadius: content.borderTopLeftRadius,
                        borderTopRightRadius: content.borderTopRightRadius,
                        borderBottomLeftRadius: content.borderBottomLeftRadius,
                        borderBottomRightRadius:
                          content.borderBottomRightRadius,
                      },
                      widthDev
                    ),
                    fontWeight: mediaQuery(
                      content,
                      "fontWeight",
                      content.fontWeight,
                      widthDev
                    ),
                  }}
                >
                  <span id={id} style={{ fontFamily: content.fontFamily }}>
                    {content.icon && content.iconPosition === "left" && (
                      <i
                        className={content.icon}
                        style={{ marginRight: "10px" }}
                      ></i>
                    )}
                    {content.content}
                  </span>
                  {content.icon && content.iconPosition === "right" && (
                    <i
                      className={content.icon}
                      style={{ marginLeft: "10px" }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          );
          break;
        case viewState.viewCountdown:
          return (
            <div
              id={id}
              style={{
                overflow: "auto",
                overflowX: "hidden",
                width: widthDev,
                textAlign: mediaQuery(
                  content,
                  "textAlign",
                  content.textAlign,
                  widthDev
                ),
                marginTop: mediaQuery(
                  content,
                  "marginTop",
                  content.marginTop,
                  widthDev
                ),
                marginRight: mediaQuery(
                  content,
                  "marginRight",
                  content.marginRight,
                  widthDev
                ),
                marginBottom: mediaQuery(
                  content,
                  "marginBottom",
                  content.marginBottom,
                  widthDev
                ),
                marginLeft: mediaQuery(
                  content,
                  "marginLeft",
                  content.marginLeft,
                  widthDev
                ),
                paddingTop: mediaQuery(
                  content,
                  "paddingTop",
                  content.paddingTop,
                  widthDev
                ),
                paddingRight: mediaQuery(
                  content,
                  "paddingRight",
                  content.paddingRight,
                  widthDev
                ),
                paddingBottom: mediaQuery(
                  content,
                  "paddingBottom",
                  content.paddingBottom,
                  widthDev
                ),
                paddingLeft: mediaQuery(
                  content,
                  "paddingLeft",
                  content.paddingLeft,
                  widthDev
                ),
              }}
              className="lpcomponent-box"
              onClick={() => toggleAction(id)}
            >
              <div id={id}>
                <Row id={id} onClick={() => toggleAction(id)}>
                  <Col
                    id={id}
                    className="countdownBox"
                    style={{
                      backgroundColor: content.backgroundColor,
                      borderRadius: "10px",
                    }}
                  >
                    <ul
                      id={id}
                      style={{
                        textAlign: "center",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownTitle"
                            : widthDev === "768px"
                            ? "countdownTitle768"
                            : "countdownTitle360"
                        }
                        style={{ color: content.color }}
                      >
                        0
                      </li>
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownContent"
                            : widthDev === "768px"
                            ? "countdownContent768"
                            : "countdownContent360"
                        }
                        style={{
                          backgroundColor: content.backgroundColor,
                          color: content.color,
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        Hari
                      </li>
                    </ul>
                  </Col>
                  <Col
                    id={id}
                    className="countdownBox"
                    style={{
                      backgroundColor: content.backgroundColor,
                      borderRadius: "10px",
                    }}
                  >
                    <ul
                      id={id}
                      style={{
                        textAlign: "center",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownTitle"
                            : widthDev === "768px"
                            ? "countdownTitle768"
                            : "countdownTitle360"
                        }
                        style={{ color: content.color }}
                      >
                        0
                      </li>
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownContent"
                            : widthDev === "768px"
                            ? "countdownContent768"
                            : "countdownContent360"
                        }
                        style={{
                          backgroundColor: content.backgroundColor,
                          color: content.color,
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        Jam
                      </li>
                    </ul>
                  </Col>
                  <Col
                    id={id}
                    className="countdownBox"
                    style={{
                      backgroundColor: content.backgroundColor,
                      borderRadius: "10px",
                    }}
                  >
                    <ul
                      id={id}
                      style={{
                        textAlign: "center",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownTitle"
                            : widthDev === "768px"
                            ? "countdownTitle768"
                            : "countdownTitle360"
                        }
                        style={{ color: content.color }}
                      >
                        0
                      </li>
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownContent"
                            : widthDev === "768px"
                            ? "countdownContent768"
                            : "countdownContent360"
                        }
                        style={{
                          backgroundColor: content.backgroundColor,
                          color: content.color,
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        Menit
                      </li>
                    </ul>
                  </Col>
                  <Col
                    id={id}
                    className="countdownBox"
                    style={{
                      backgroundColor: content.backgroundColor,
                      borderRadius: "10px",
                    }}
                  >
                    <ul
                      id={id}
                      style={{
                        textAlign: "center",
                        listStyle: "none",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownTitle"
                            : widthDev === "768px"
                            ? "countdownTitle768"
                            : "countdownTitle360"
                        }
                        style={{ color: content.color }}
                      >
                        0
                      </li>
                      <li
                        id={id}
                        className={
                          widthDev === "100%"
                            ? "countdownContent"
                            : widthDev === "768px"
                            ? "countdownContent768"
                            : "countdownContent360"
                        }
                        style={{
                          backgroundColor: content.backgroundColor,
                          color: content.color,
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          padding: "5px",
                        }}
                      >
                        Detik
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

              <ActionButton selectedContent={content} id={id}></ActionButton>
            </div>
          );

          break;
        case viewState.viewMap:
          if (content.iframe) {
            var iframeData = content.iframe.toString();
            var src = iframeData.split("src=")[1].split(/[ >]/)[0];
            src = src.substring(1);
            src = src.substring(0, src.length - 1);
            // alert(src)
            return (
              <div
                id={id}
                className="lpcomponent-box"
                onClick={() => toggleAction(id)}
                style={{ overflow: "auto" }}
              >
                <div
                  id={id}
                  style={{
                    overflow: "auto",
                    width: "100%",
                    height: "450",
                    touchAction: "none",
                  }}
                >
                  <div id={id}>
                    <img
                      style={{ overflow: "auto" }}
                      id={id}
                      src="https://d1jcw7e5n1q2pt.cloudfront.net/1701850491033-map.png"
                      width="100%"
                      height="450"
                    />
                    {/* {src && <Iframe url={src} width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></Iframe>} */}
                  </div>
                  <ActionButton
                    selectedContent={content}
                    id={id}
                  ></ActionButton>
                </div>
              </div>
            );
          }
          return "    ";
          break;
      }
    }
  };
  const renderView = (content, id) => {
    setTimeout(() => {
      if (content?.type) {
        if (content.type === viewState.viewCountdown) {
          content.fontSizeTitle = "40px";
          content.fontSizeContent = "20px";
        }
      }
    }, 1);
    return switchType(content, id);
  };

  const renderHTML = (value) => {
    return value;
  };
  const fontTags = (content, id) => {
    let teksnya = (
      <p
        id={id}
        style={{
          position: visualColom(content, "position", content.position),
          zIndex: visualColom(content, "zIndex", content.zIndex),
          textShadow: visualColom(content, "textShadow", content.textShadow),
          writingMode: visualColom(content, "writingMode", content.writingMode),
          borderRadius: borderRadiusMediaQuery(
            content,
            "borderRadius",
            {
              borderRadius: content.borderRadius,
              borderTopLeftRadius: content.borderTopLeftRadius,
              borderTopRightRadius: content.borderTopRightRadius,
              borderBottomLeftRadius: content.borderBottomLeftRadius,
              borderBottomRightRadius: content.borderBottomRightRadius,
            },
            widthDev
          ),
          background:
            content.isTransparent === true
              ? "rgba(0,0,0,0)"
              : content.backgroundColor,
          marginTop: visualColom(content, "marginTop", content.marginTop),
          marginRight: visualColom(content, "marginRight", content.marginRight),
          marginBottom: visualColom(
            content,
            "marginBottom",
            content.marginBottom
          ),
          marginLeft: visualColom(content, "marginLeft", content.marginLeft),
          paddingTop: visualColom(content, "paddingTop", content.paddingTop),
          paddingRight: visualColom(
            content,
            "paddingRight",
            content.paddingRight
          ),
          paddingBottom: visualColom(
            content,
            "paddingBottom",
            content.paddingBottom
          ),
          paddingLeft: visualColom(content, "paddingLeft", content.paddingLeft),
          textAlign: visualColom(content, "textAlign", content.textAlign),
          fontSize: visualColom(content, "fontSize", content.fontSize),
          fontWeight: visualColom(content, "fontWeight", content.fontWeight),
          color: visualColom(content, "color", content.color),
          fontFamily: visualColom(content, "fontFamily", content.fontFamily),
          lineHeight: visualColom(content, "lineHeight", content.lineHeight),
        }}
      >
        {" "}
        {renderHTML(content.content)}
      </p>
    );
    return <>{teksnya}</>;
  };

  const IconAction = (action, selectedContent, id) => {
    if (action === "remove") {
      selectedContent.id = id;
      removeItem(selectedContent);
    }
    if (action === "update") {
      selectedContent.id = id;
      changeItem(selectedContent);
    }
  };

  const visualColom = (row, key, value) => {
    switch (widthDev) {
      case MEDIALARGE:
        return value;
      case MEDIAMEDIUM:
        if (row.mediaQueri768 && row.mediaQueri768[key]) {
          return row.mediaQueri768[key];
        } else {
          return value;
        }
      case MEDIASMALL:
        if (row.mediaQueri360 && row.mediaQueri360[key]) {
          return row.mediaQueri360[key];
        } else {
          if (row.mediaQueri768 && row.mediaQueri768[key]) {
            return row.mediaQueri768[key];
          } else {
            return value;
          }
        }
    }
  };

  const getIndexCol = (id) => {
    if (id) {
      let currentId = id.split("-");
      currentId.splice(-1);
      let colmenuID = "";
      for (let a = 0; a < currentId.length; a++) {
        colmenuID = colmenuID + currentId[a];
        if (a !== currentId.length - 1) {
          colmenuID = colmenuID + "-";
        }
      }
      return colmenuID;
    }
  };

  return (
    <div
      id={idview}
      onMouseOver={() => {
        showSlide(rowIndex);
        showColMenu(getIndexCol(colom?.id));
      }}
    >
      {renderView(colom, idview)}
    </div>
  );
};

export default LandingpageColom;
