import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import ColorComp from "../form/ColorComp";
import GoogleFontsComp from "../form/GoogleFontsComp";
import AnimationComp from "../form/AnimationComp";
import mediaQuery, {
  clearBorderRadius,
  isAdvancedRadius,
} from "../../helpers/mediaQueryHelper";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
} from "../../variable";
import BorderRadiusComp from "../form/BorderRadiusComp";
import ButtonSpaceComp from "../ButtonSpaceComp";
import rgbToHex from "../../helpers/rgbToHexHelper";
import InputComp from "../form/InputComp";
const FormView = ({ data, ViewCB, fontCB, widthDevice }) => {
  const listResponsiveKey = [
    "marginTop",
    "marginLeft",
    "marginRight",
    "marginBottom",
    "paddingTop",
    "paddingRight",
    "paddingBottom",
    "paddingLeft",
    "backgroundColor",
    "color",
    "borderType",
    "borderColor",
    "buttonColor",
    "buttonBackground",
    "customClassName",
  ];
  const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(
    isAdvancedRadius(data)
  );
  const delay = 1;
  let desc;
  const [key, setKey] = useState("main");
  const [colData, setColData] = useState(data);
  const [isReady, setIsReady] = useState(false);
  const [masterData, setMasterData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const setButtonTeks = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        buttonTeks: value,
      };
    });
  };

  const changeBackgroundColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        backgroundColor: value.hex,
        isTransparent: false,
      };
    });
  };

  const changeColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        color: value.hex,
      };
    });
  };

  const changeButtonBackground = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        buttonBackground: value.hex,
      };
    });
  };

  const changeButtonColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        buttonColor: value.hex,
      };
    });
  };

  const changeData = (key, value) => {
    setColData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    let desc = setTimeout(() => {
      if (colData) {
        ViewCB(colData);
        setMasterData(colData);
      }
    }, delay * 1000);
    return () => {
      clearTimeout(desc);
    };
  }, [colData]);

  const cbValue = (s_data, isGlobal) => {
    let key = labelCSS(s_data.key);
    let value = s_data.value;
    let extension = key === "width" ? "%" : "px";
    if (
      s_data.key === "textAlign" ||
      s_data.key === "shape" ||
      s_data.key === "zIndex" ||
      s_data.key === "customClassName" ||
      s_data.key === "rotate" ||
      s_data.key === "positionAbsolute" ||
      s_data.key === "borderStyle"
    ) {
      extension = "";
    }
    if (widthDevice === MEDIALARGE || isGlobal) {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value + extension,
        };
      });
    } else {
      if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
        let widthDeviceStr = SIZEMEDIUM;
        if (widthDevice === MEDIASMALL) {
          widthDeviceStr = SIZESMALL;
        }
        let temp = { ...colData };
        console.log("kena", s_data);
        if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
          let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
          let newObject = {
            [key]: value + extension,
          };
          let newJoin = { ...tempNewObject, ...newObject };
          temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
          setColData(temp);
        } else {
          temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
            [key]: value + extension,
          };
          setColData(temp);
        }
      }
    }
  };

  const noColor = () => {
    setColData((prev) => {
      return {
        ...prev,
        isTransparent: true,
        backgroundColor: "transparent",
      };
    });
  };

  const boderRadiusAdvancedMode = (val) => {
    setIsBorderRadiusAdvanced(val);
    let resetRadius = clearBorderRadius(masterData);
    setColData(resetRadius);
  };

  const calculateSetting = () => {
    setIsReady(false);
    let tempState = { ...masterData };
    for (let a = 0; a < listResponsiveKey.length; a++) {
      tempState[listResponsiveKey[a]] = mediaQuery(
        tempState,
        listResponsiveKey[a],
        tempState[listResponsiveKey[a]],
        widthDevice
      );
    }
    if (isBorderRadiusAdvanced) {
      tempState.borderRadius = 0;
      tempState.borderTopLeftRadius = mediaQuery(
        tempState,
        "borderTopLeftRadius",
        tempState.borderTopLeftRadius,
        widthDevice
      );
      tempState.borderTopRightRadius = mediaQuery(
        tempState,
        "borderTopRightRadius",
        tempState.borderTopRightRadius,
        widthDevice
      );
      tempState.borderBottomLeftRadius = mediaQuery(
        tempState,
        "borderBottomLeftRadius",
        tempState.borderBottomLeftRadius,
        widthDevice
      );
      tempState.borderBottomRightRadius = mediaQuery(
        tempState,
        "borderBottomRightRadius",
        tempState.borderBottomRightRadius,
        widthDevice
      );
    } else {
      tempState.borderRadius = mediaQuery(
        tempState,
        "borderRadius",
        tempState.borderRadius,
        widthDevice
      );
      tempState.borderTopLeftRadius = 0;
      tempState.borderTopRightRadius = 0;
      tempState.borderBottomLeftRadius = 0;
      tempState.borderBottomRightRadius = 0;
    }

    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    console.log("masterData", masterData);
  }, [masterData]);

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
    }
  }, [widthDevice]);

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <>
              <InputComp
                type="text"
                label="CSS Class"
                placeholder="Masukkan CSS Class"
                value={masterData.customClassName}
                isRequired={false}
                onChange={(val) => {
                  let key = "customClassName";
                  let newData = {
                    key,
                    value: val,
                  };
                  cbValue(newData, true);
                }}
              ></InputComp>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Label nama
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.nameLabel}
                      onChange={($e) =>
                        changeData("nameLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Placeholder nama
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.namePlaceholder}
                      onChange={($e) =>
                        changeData("namePlaceholder", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Label Pesan
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.pesanLabel}
                      onChange={($e) =>
                        changeData("pesanLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Placeholder pesan
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.pesanPlaceholder}
                      onChange={($e) =>
                        changeData("pesanPlaceholder", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Question Label
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.questionLabel}
                      onChange={($e) =>
                        changeData("questionLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>hadir Label
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.hadirLabel}
                      onChange={($e) =>
                        changeData("hadirLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>tidak Hadir Label
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.tidakHadirLabel}
                      onChange={($e) =>
                        changeData("tidakHadirLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>ragu Label
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.raguLabel}
                      onChange={($e) =>
                        changeData("raguLabel", $e.target.value)
                      }
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Teks Tombol
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={colData.buttonTeks}
                      onChange={($e) => setButtonTeks($e.target.value)}
                      className="form-control-dark"
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <GoogleFontsComp
                value={colData.fontFamily}
                label="Font Family"
                cbValue={cbValue}
              ></GoogleFontsComp>
              <RangeSlider
                value={colData.fontSize}
                label="Font Size"
                cbValue={cbValue}
                maxvalue={100}
              ></RangeSlider>

              <ColorComp
                label="Background"
                colorCB={changeBackgroundColor}
                primaryColor={rgbToHex(masterData.backgroundColor)}
                showNoColor={true}
                noColor={noColor}
              ></ColorComp>
              <ColorComp
                label="Color"
                colorCB={changeColor}
                primaryColor={rgbToHex(masterData.color)}
              ></ColorComp>
              <ColorComp
                label="Button Background"
                colorCB={changeButtonBackground}
                primaryColor={rgbToHex(masterData.buttonBackground)}
              ></ColorComp>
              <ColorComp
                label="Button Color"
                colorCB={changeButtonColor}
                primaryColor={rgbToHex(masterData.buttonColor)}
              ></ColorComp>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <BorderRadiusComp
                value={masterData.borderRadius}
                label="Border Radius"
                cbValue={cbValue}
                maxvalue="100"
                advancedValue={{
                  borderTopLeftRadius: masterData.borderTopLeftRadius,
                  borderTopRightRadius: masterData.borderTopRightRadius,
                  borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                  borderBottomRightRadius: masterData.borderBottomRightRadius,
                }}
                isAdvancedMode={isBorderRadiusAdvanced}
                adVancedModeCB={boderRadiusAdvancedMode}
              ></BorderRadiusComp>
              {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue}></RangeSlider> */}
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginRight}
                label="Margin Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginLeft}
                label="Margin Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="animation" title="animasi">
          visual{" "}
          <AnimationComp
            value={colData.animation ? colData.animation : ""}
            cbValue={cbAnimation}
          ></AnimationComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default FormView;
