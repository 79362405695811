import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import InvitationPage from "./pages/Invitation/InvitationPage";
import WebsiteEditorPage from "./pages/Editor/WebsiteEditorPage";
import RSVPPage from "./pages/RSVP";
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from "./pages/ProfilePage";
import SettingPage from "./pages/SettingPage";
import LogsPage from "./pages/LogsPage";
import LoginPage from "./pages/LoginPages";
import LogoutPages from "./pages/LogoutPages";
import UserPage from "./pages/users";

const routerProtectedOwner = createBrowserRouter([
  // {
  //   path: "/",
  //   element: <UserPage></UserPage>,
  // },
  // {
  //   path: "/profile",
  //   element: <ProfilePage></ProfilePage>,
  // },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/logout",
    element: <LogoutPages></LogoutPages>,
  },
  {
    path: "*",
    element: <NotFoundPage></NotFoundPage>,
  },
]);

export default routerProtectedOwner;
