import axiosInstance from "../helpers/axiosHelper";

const path = "/api/v1/customer/invitation";
export const getLandingpages = (pageSize, page, q) => {
  return axiosInstance.get(`${path}?pageSize=${pageSize}&page=${page}${q}`);
};

export const getLandingpageDetail = (id_landingpage) => {
  return axiosInstance.get(`${path}/${id_landingpage}`);
};

export const updateLandingpage = (id, data) => {
  return axiosInstance.put(`${path}/${id}`, data);
};

export const updatePluginServices = (id, data) => {
  return axiosInstance.put(`${path}/plugins/${id}`, data);
};
export const getPluginServices = (id) => {
  return axiosInstance.get(`${path}/plugins/${id}`);
};
export const deleteLandingpage = (id) => {
  return axiosInstance.delete(`${path}/${id}`);
};
export const deleteComment = (id, data) => {
  return axiosInstance.put(`${path}/form/${id}`, data);
};
export const createLandingpage = (data) => {
  return axiosInstance.post(`${path}`, data);
};
export const duplicateLandingpage = (id, name) => {
  return axiosInstance.post(`${path}/${id}`, name);
};
