import React, { useState, useEffect } from "react";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import { Form, Tabs, Tab } from "react-bootstrap";
import AnimationComp from "../form/AnimationComp";
import uploadHelper from "../../helpers/uploadHelper";
import LoaderComp from "../LoaderComp";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
} from "../../variable";
import mediaQuery from "../../helpers/mediaQueryHelper";
import convertToWebP from "../../helpers/webPHelper";
const ImageSliderView = ({ data, ViewCB, widthDevice }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [key, setKey] = useState("main");
  const [colData, setColData] = useState(data);
  const [isReady, setIsReady] = useState(false);
  const [masterData, setMasterData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const cbValue = (data) => {
    let key = labelCSS(data.key);

    let value = data.value;
    let extension = key === "width" ? "%" : "px";
    if (data.key === "textAlign") {
      extension = "";
    }
    if (widthDevice === MEDIALARGE) {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value + extension,
        };
      });
    }
    if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
      let widthDeviceStr = SIZEMEDIUM;
      if (widthDevice === MEDIASMALL) {
        widthDeviceStr = SIZESMALL;
      }
      let temp = { ...colData };
      if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
        let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
        let newObject = {
          [key]: value,
        };
        let newJoin = { ...tempNewObject, ...newObject };
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
        setColData(temp);
      } else {
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
          [key]: value,
        };
        setColData(temp);
      }
    }
  };

  useEffect(() => {
    if (colData && data) {
      ViewCB(colData);
      setMasterData(colData);
    }
  }, [colData]);

  const calculateSetting = () => {
    setIsReady(false);
    let tempState = { ...masterData };
    tempState.marginTop = mediaQuery(
      tempState,
      "marginTop",
      tempState.marginTop,
      widthDevice
    );
    tempState.marginLeft = mediaQuery(
      tempState,
      "marginLeft",
      tempState.marginLeft,
      widthDevice
    );
    tempState.marginRight = mediaQuery(
      tempState,
      "marginRight",
      tempState.marginRight,
      widthDevice
    );
    tempState.marginBottom = mediaQuery(
      tempState,
      "marginBottom",
      tempState.marginBottom,
      widthDevice
    );
    tempState.paddingTop = mediaQuery(
      tempState,
      "paddingTop",
      tempState.paddingTop,
      widthDevice
    );
    tempState.paddingRight = mediaQuery(
      tempState,
      "paddingRight",
      tempState.paddingRight,
      widthDevice
    );
    tempState.paddingBottom = mediaQuery(
      tempState,
      "paddingBottom",
      tempState.paddingBottom,
      widthDevice
    );
    tempState.paddingLeft = mediaQuery(
      tempState,
      "paddingLeft",
      tempState.paddingLeft,
      widthDevice
    );
    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
    }
  }, [widthDevice]);

  const uploadfile = async (event) => {
    try {
      setIsUploading(true);
      let webPImages = await convertToWebP(event.target.files);
      var datatemp = { ...colData };
      if (
        datatemp.image.length > 0 &&
        datatemp.image[0].img ===
          "https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg"
      ) {
        var tempImages = [];
      } else {
        var tempImages = datatemp.image;
      }
      let result = await uploadHelper(event, event.target.files.length);
      if (result) {
        for (let a = 0; a < result.length; a++) {
          tempImages = [
            ...tempImages,
            {
              img: result[a].widthImage,
              imagewebp: webPImages[a],
            },
          ];
        }
        setColData((prev) => {
          return {
            ...prev,
            image: tempImages,
          };
        });
      }
      setIsUploading(false);
    } catch {
      alert("gagal upload file tidak compatible");
      setIsUploading(false);
    }
  };

  const removeImage = (index) => {
    var datatemp = { ...colData };
    datatemp.image.splice(index, 1);
    if (datatemp.image.length < 1) {
      datatemp.image = [];
    }
    setColData(datatemp);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span> Gambar Produk
                </Form.Label>
                <Form.Control
                  id="custom-file"
                  label="Custom file input"
                  name="input2[]"
                  type="file"
                  className="file"
                  data-show-upload="false"
                  data-show-caption="true"
                  custom
                  multiple
                  onChange={($e) => {
                    uploadfile($e);
                  }}
                />
                <div>
                  <i>support jpg/png/gif/svg max 5mb</i>
                </div>
              </Form.Group>
              {isUploading ? (
                <LoaderComp></LoaderComp>
              ) : (
                <>
                  {colData.image &&
                    colData.image.map((img, index) => {
                      return (
                        <div style={{ display: "flex" }} key={index}>
                          <div
                            style={{
                              flex: 1,
                              marginBottom: "10px",
                              position: "relative",
                            }}
                          >
                            <img src={img.img} className="img-fluid" />
                            <div
                              style={{
                                position: "absolute",
                                width: "50px",
                                top: 0,
                                right: "0px",
                                height: "40px",
                                background: "#000",
                                zIndex: 1,
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => removeImage(index)}
                            >
                              <i
                                className="fas fa-times"
                                style={{
                                  zIndex: 100,
                                  fontSize: 22,
                                  color: "red",
                                  cursor: "pointer",
                                  paddingTop: "10px",
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </>
          )}
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginRight}
                label="Margin Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginLeft}
                label="Margin Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
        </Tab>
        <Tab eventKey="animation" title="animasi">
          visual{" "}
          <AnimationComp
            value={colData.animation ? colData.animation : ""}
            cbValue={cbAnimation}
          ></AnimationComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ImageSliderView;
