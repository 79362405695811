import { TRANSCOLOR, TRANSCOLORRGBA } from "../variable";
let errorMessage = "";
const encryptSpace = (value) => {
  return value.replace(/ /g, "%20");
};

const pushFont = (content, temp) => {
  if (content.fontWeight === undefined) {
    content.fontWeight = 300;
  }
  let newWeight = [content.fontWeight];
  temp.push({
    font: content.fontFamily,
    weights: newWeight,
  });
  return temp;
};

const removeProperty = (obj, key) => {
  let foundIndex = obj.findIndex((x) => {
    if (x[key]) {
      return x;
    }
  });
  if (foundIndex !== -1) {
    obj.splice(foundIndex, 1);
  }
  return obj;
};

const checkingFonts = (content, temp) => {
  if (temp.length > 0) {
    let fontExist = temp.find((f) => f.font === content.fontFamily);
    if (fontExist) {
      temp.filter((f) => {
        if (f.font === content.fontFamily) {
          if (f.weights) {
            if (!f.weights.includes(content.fontWeight)) {
              if (content.fontWeight !== undefined) {
                f.weights.push(content.fontWeight);
              }
            }
          }
        }
      });
    } else {
      temp = pushFont(content, temp);
    }
  } else {
    temp = pushFont(content, temp);
  }
  return temp;
};

const removeExtension = (value, ext) => {
  if (value.includes(ext)) {
    value = value.replace(ext, "");
    return value;
  } else {
    return value;
  }
};

const includeExtension = (value, extension) => {
  if (value) {
    let valueString = value.toString();
    valueString = removeExtension(valueString, "rem");
    if (valueString.includes("px")) {
      return valueString;
    } else {
      return valueString + extension;
    }
  } else {
    return value;
  }
};

const checkingBorderRadius = (realObject, obj) => {
  if (realObject.borderRadius) {
    if (
      realObject.borderBottomRightRadius ||
      realObject.borderBottomLeftRadius ||
      realObject.borderTopLeftRadius ||
      realObject.borderTopRightRadius
    ) {
      if (
        realObject.borderBottomRightRadius !== 0 ||
        realObject.borderBottomLeftRadius !== 0 ||
        realObject.borderTopLeftRadius !== 0 ||
        realObject.borderTopRightRadius !== 0
      ) {
        obj.push({
          "border-bottom-right-radius": includeExtension(
            realObject.borderBottomRightRadius,
            "px"
          ),
        });
        obj.push({
          "border-bottom-left-radius": includeExtension(
            realObject.borderBottomLeftRadius,
            "px"
          ),
        });
        obj.push({
          "border-top-left-radius": includeExtension(
            realObject.borderTopLeftRadius,
            "px"
          ),
        });
        obj.push({
          "border-top-right-radius": includeExtension(
            realObject.borderTopRightRadius,
            "px"
          ),
        });
      } else {
        if (realObject?.borderRadius !== "px") {
          obj.push({
            "border-radius": includeExtension(realObject.borderRadius, "px"),
          });
        }
      }
    } else {
      if (realObject?.borderRadius !== "px") {
        obj.push({
          "border-radius": includeExtension(realObject.borderRadius, "px"),
        });
      }
    }
  }
  return obj;
};

const marginPaddingChecker = (key, value) => {
  if (key.includes("padding") || key.includes("margin")) {
    if (value.toString().includes("px")) {
      return {
        key: key,
        value: value,
      };
    } else if (value.toString().includes("rem")) {
      return {
        key: key,
        value: value.toString(),
      };
    } else {
      return {
        key: key,
        value: value.toString() + "px",
      };
    }
  } else {
    return {
      key: key,
      value: value,
    };
  }
};
const slideConvert = (realObject) => {
  let obj = [];
  let obj360 = [];
  let obj768 = [];
  let objInner = [];
  let objInner360 = [];
  let objInner768 = [];
  let xtract2 = extractObject(realObject);
  let objKeys2 = xtract2.objKeys;
  let objValues2 = xtract2.objValues;

  for (let b = 0; b < objKeys2.length; b++) {
    if (objValues2[b]) {
      if (
        objValues2[b] !== undefined ||
        objValues2[b].toString().trim() !== ""
      ) {
        let key = objKeys2[b];
        let value = objValues2[b];
        if (key === "width") {
          if (value < 100) {
            let marginLeft = (100 - value) / 2;
            if (obj !== undefined) {
              obj.push({ left: `${marginLeft}%` });
            }
          }
          value = `${value}%`;
        }
        let res = marginPaddingChecker(key, value);
        key = res.key;
        value = res.value;
        let isSupported = CSS.supports(key, value);
        if (isSupported) {
          if (key === "width" || key === "flex") {
            let indexWidthType = objKeys2.indexOf("width-type");
            if (indexWidthType > 0) {
              if (objValues2[indexWidthType] === "flex") {
                let indexFlex = objKeys2.indexOf("flex");
                obj.push({ flex: objValues2[indexFlex] });
              } else {
                let indexWidth = objKeys2.indexOf("width");
                obj.push({ width: `${objValues2[indexWidth]}px !important` });
              }
            } else {
              let indexWidth = objKeys2.indexOf("width");
              obj.push({ width: `${objValues2[indexWidth]}%` });
            }
          } else if (
            key === "border-radius" ||
            key === "border-top-left-radius" ||
            key === "border-top-right-radius" ||
            key === "border-bottom-left-radius" ||
            key === "border-bottom-right-radius"
          ) {
          } else if (
            key === "padding-top" ||
            key === "padding-right" ||
            key === "padding-bottom" ||
            key === "padding-left" ||
            key === "flex-direction" ||
            key === "direction"
          ) {
            if (key === "direction") {
              key = "flex-direction";
            }
            if (value !== undefined || value.toString().trim() !== "") {
              objInner.push({ [key]: value });
            }
          } else {
            if (key !== "opacity" && key !== "background-color") {
              if (obj !== undefined) {
                obj.push({ [key]: value });
              }
            }
          }
        } else {
          if (key === "background-image") {
            if (realObject.hasOwnProperty("backgroundType")) {
              if (realObject.backgroundType === "image") {
                obj.push({ [key]: `url("${value}")` });
                obj.push({ "background-position": `center%20center` });
              }
            }
          }
          if (key === "cover") {
            obj.push({ "background-size": "cover" });
            obj.push({ "background-position": "center" });
          }
          if (
            key === "border-radius" ||
            key === "border-top-left-radius" ||
            key === "border-top-right-radius" ||
            key === "border-bottom-left-radius" ||
            key === "border-bottom-right-radius"
          ) {
            obj.push({ [key]: `${value}px` });
          }
        }
      }
    }
  }

  if (realObject.backgroundType === "color") {
    let primaryColor = realObject.backgroundColor;
    if (primaryColor === TRANSCOLOR) {
      primaryColor = TRANSCOLORRGBA;
    }
    if (realObject.linear === "linear") {
      let secondaryColor = realObject.backgroundColor2;
      if (secondaryColor === TRANSCOLOR) {
        secondaryColor = TRANSCOLORRGBA;
      }
      obj.push({
        background: `linear-gradient(to%20bottom,${primaryColor}, ${secondaryColor})`,
      });
    } else {
      obj.push({ background: primaryColor });
    }
  }
  if (realObject.backgroundType === "slide") {
    let primaryColor = realObject.cover.backgroundColor;
    if (primaryColor === TRANSCOLOR) {
      primaryColor = TRANSCOLORRGBA;
    }
    if (realObject.linear === "linear") {
      let secondaryColor = realObject.cover2.backgroundColor;
      if (secondaryColor === TRANSCOLOR) {
        secondaryColor = TRANSCOLORRGBA;
      }
      objInner.push({
        background: `linear-gradient(to%20bottom,${primaryColor}, ${secondaryColor})`,
      });
    } else {
      objInner.push({ background: primaryColor });
    }
  }
  if (realObject.backgroundType === "image") {
    let primaryColor = realObject.cover.backgroundColor;
    if (primaryColor === TRANSCOLOR) {
      primaryColor = TRANSCOLORRGBA;
    }
    if (realObject.linear === "linear") {
      let secondaryColor = realObject.cover2.backgroundColor;
      if (secondaryColor === TRANSCOLOR) {
        secondaryColor = TRANSCOLORRGBA;
      }
      objInner.push({
        background: `linear-gradient(to%20bottom,${primaryColor}, ${secondaryColor})`,
      });
    } else {
      objInner.push({ background: primaryColor });
    }
  }

  if (realObject.mediaQueri360) {
    for (let [key, value] of Object.entries(realObject.mediaQueri360)) {
      if (
        key === "marginTop" ||
        key === "marginRight" ||
        key === "marginBottom" ||
        key === "marginLeft"
      ) {
        obj360.push({
          [key]: value,
        });
      } else {
        objInner360.push({
          [key]: value,
        });
      }
    }
    if (realObject.position !== "fixed") {
      obj360.width = "100% !important";
      if (obj360.left) {
        obj360.left = "0 !important";
      }
      if (obj360.right) {
        obj360.right = "0 !important";
      }
    }
  }

  if (realObject.mediaQueri768) {
    for (let [key, value] of Object.entries(realObject.mediaQueri768)) {
      if (
        key === "marginTop" ||
        key === "marginRight" ||
        key === "marginBottom" ||
        key === "marginLeft"
      ) {
        obj768.push({
          [key]: value,
        });
      } else {
        objInner768.push({
          [key]: value,
        });
      }
    }
    if (realObject.position !== "fixed") {
      obj768.width = "100% !important";
      if (obj768.left) {
        obj768.left = "0 !important";
      }
      if (obj360.right) {
        obj768.right = "0 !important";
      }
    }
  }
  if (realObject.position === "fixed") {
    if (obj !== undefined) {
      obj.push({ "z-index": `9999` });
    }
    if (realObject.fixedTo === "bottom") {
      obj.push({ bottom: 0 });
    } else {
      obj.push({ top: 0 });
    }
  } else {
    obj.width = "100%";
    if (obj.left) {
      obj.left = 0;
    }
    if (obj.right) {
      obj.right = 0;
    }
  }
  obj = checkingBorderRadius(realObject, obj);

  return {
    obj: obj,
    obj360: obj360,
    obj768: obj768,
    objInner: objInner,
    objInner360: objInner360,
    objInner768: objInner768,
  };
};

const hasFlexFunc = (obj, key) => {
  if (!key) {
    key = "flexDirection";
  }
  let hasFlex = obj.find((objInner) => objInner[key]);
  if (hasFlex) {
    let customcolObj = {
      display: "flex",
      [key]: hasFlex[key],
    };
    return customcolObj;
  } else {
    return false;
  }
};
const colomConvert = (
  cssCodes,
  cssCodes500,
  cssCodes900,
  parent,
  classname,
  filterFontFamily,
  mediaQueryPhone,
  mediaQueryTablet,
  colomQueryPhone,
  colomQueryTablet,
  isResponsive
) => {
  let totalColom = parent.length;
  for (let a = 0; a < totalColom; a++) {
    let wrapper = slideConvert(parent[a], a);
    cssCodes = pushCSS(`${classname}${a}`, cssCodes, wrapper.obj);
    cssCodes = pushCSS(`${classname}inner-${a}`, cssCodes, wrapper.objInner);

    const innerFlex = hasFlexFunc(wrapper.objInner, "flex-direction");
    if (innerFlex !== false) {
      let hasPosition = wrapper.obj.find((objPosition) => objPosition.position);
      let newData = [];
      newData.push({
        display: "flex",
      });
      newData.push({
        ["flex-direction"]: innerFlex["flex-direction"],
      });
      newData.push({
        position: hasPosition?.position,
      });
      if (!classname.includes("content")) {
        cssCodes = pushCSS(`${classname}${a}-customcol`, cssCodes, newData);
      }
    }
    const flexDirectionObj = wrapper.objInner.find(
      (inner) => inner["flex-direction"]
    );
    const flexDirectionValue = flexDirectionObj
      ? flexDirectionObj["flex-direction"]
      : null;
    if (!isResponsive) {
      if (wrapper.obj768) {
        cssCodes = pushCSS(`${classname}${a}`, cssCodes, wrapper.obj768);
      }
      if (wrapper.objInner768) {
        cssCodes = pushCSS(
          `${classname}inner-${a}`,
          cssCodes,
          wrapper.objInner768
        );
      }
      if (wrapper.obj360) {
        cssCodes = pushCSS(`${classname}${a}`, cssCodes, wrapper.obj360);
      }
      if (wrapper.objInner360) {
        cssCodes = pushCSS(
          `${classname}inner-${a}`,
          cssCodes,
          wrapper.objInner360
        );
      }
    }
    if (wrapper.obj360) {
      mediaQueryPhone.push({
        classname: { ...wrapper.obj360, tag: `${classname}${a}` },
      });
    }
    if (wrapper.objInner360) {
      mediaQueryPhone.push({
        classname: { ...wrapper.objInner360, tag: `${classname}inner-${a}` },
      });
      const customColObj1 = hasFlexFunc(wrapper.objInner360);
      if (customColObj1 !== false) {
        mediaQueryPhone.push({
          classname: { ...customColObj1, tag: `${classname}${a}-customcol` },
        });
      }
    }
    if (wrapper.obj768) {
      mediaQueryTablet.push({
        classname: { ...wrapper.obj768, tag: `${classname}${a}` },
      });
    }
    if (wrapper.objInner768) {
      mediaQueryTablet.push({
        classname: { ...wrapper.objInner768, tag: `${classname}inner-${a}` },
      });
      let customColObj2 = hasFlexFunc(wrapper.objInner768);
      if (customColObj2 !== false) {
        mediaQueryTablet.push({
          classname: { ...customColObj2, tag: `${classname}${a}-customcol` },
        });
      }
    }
    if (parent[a].content) {
      let content = parent[a].content;
      let TotalContent = parent[a].content.length;
      for (let cab = 0; cab < TotalContent; cab++) {
        let newContent = { ...content[cab] };
        let contentObj = contentConvert(
          newContent,
          filterFontFamily,
          isResponsive
        );
        if (contentObj) {
          cssCodes = pushCSS(
            `${classname}${a}-comp-${cab}`,
            cssCodes,
            contentObj.obj
          );
          if (contentObj.hover) {
            cssCodes = pushCSS(
              `${classname}${a}-comp-${cab}`,
              cssCodes,
              contentObj.hover,
              "hover"
            );
          }
          if (contentObj.inner) {
            cssCodes = pushCSS(
              `${classname}${a}-comp-${cab}`,
              cssCodes,
              contentObj.inner,
              "inner"
            );
          }
          if (contentObj.wrapper) {
            cssCodes = pushCSS(
              `${classname}${a}-comp-${cab}`,
              cssCodes,
              contentObj.wrapper,
              "wrapper"
            );
          }

          if (contentObj.mediaQueri768 !== "") {
            mediaQueryTablet.push({
              classname: {
                ...contentObj.mediaQueri768,
                tag: `${classname}${a}-comp-${cab}`,
              },
            });
            if (!isResponsive) {
              cssCodes = pushCSS(
                `${classname}${a}-comp-${cab}`,
                cssCodes,
                contentObj.mediaQueri768
              );
            }

            if (contentObj?.mediaQueri768?.type === "Button") {
              if (contentObj?.mediaQueri768?.textAlign) {
                mediaQueryPhone.push({
                  classname: {
                    textAlign: contentObj?.mediaQueri768?.textAlign,
                    tag: `${classname}${a}-comp-${cab}-wrapper`,
                  },
                });
              }
            }
          }
          if (contentObj.mediaQueri360 !== "") {
            mediaQueryPhone.push({
              classname: {
                ...contentObj.mediaQueri360,
                tag: `${classname}${a}-comp-${cab}`,
              },
            });
            if (contentObj?.mediaQueri360?.type === "Button") {
              if (contentObj?.mediaQueri360?.textAlign) {
                mediaQueryPhone.push({
                  classname: {
                    textAlign: contentObj?.mediaQueri360?.textAlign,
                    tag: `${classname}${a}-comp-${cab}-wrapper`,
                  },
                });
              }
            }
            if (!isResponsive) {
              cssCodes = pushCSS(
                `${classname}${a}-comp-${cab}`,
                cssCodes,
                contentObj.mediaQueri360
              );
            }
          }
          //has custom colom
          if (content[cab].content) {
            //here
            for (let aj = 0; aj < content[cab].content.length; aj++) {
              if (content[cab].content[aj].content) {
                content[cab].content[aj].flexDirection = flexDirectionValue;
              }
            }
            let xtractCSS = colomConvert(
              cssCodes,
              cssCodes500,
              cssCodes900,
              content[cab].content,
              `${classname}${a}-content-${cab}-col-`,
              filterFontFamily,
              mediaQueryPhone,
              mediaQueryTablet,
              colomQueryPhone,
              colomQueryTablet,
              isResponsive
            );

            cssCodes = xtractCSS.cssCodes;
            cssCodes500 = xtractCSS.cssCodes500;
            cssCodes900 = xtractCSS.cssCodes900;
          }
        }
      }
    }
    if (parent[a].mediaQueri360) {
      let extCol = slideConvert(parent[a].mediaQueri360);
      cssCodes500 = pushCSS(`${classname}${a}`, cssCodes500, extCol.obj);
      cssCodes500 = pushCSS(
        `${classname}inner-${a}`,
        cssCodes500,
        extCol.objInner
      );
      if (!isResponsive) {
        let extColNotRes = slideConvert(parent[a].mediaQueri360);
        cssCodes = pushCSS(`${classname}${a}`, cssCodes, extColNotRes.obj);
        cssCodes = pushCSS(
          `${classname}inner-${a}`,
          cssCodes,
          extColNotRes.objInner
        );
      }
    }
    if (parent[a].mediaQueri768) {
      let extCol = slideConvert(parent[a].mediaQueri768);
      cssCodes900 = pushCSS(`${classname}${a}`, cssCodes900, extCol.obj);
      cssCodes900 = pushCSS(
        `${classname}inner-${a}`,
        cssCodes900,
        extCol.objInner
      );
    }
  }
  return {
    cssCodes: cssCodes,
    cssCodes500: cssCodes500,
    cssCodes900: cssCodes900,
  };
};

const unsupportedTag = (obj, content, key, value) => {
  if (content.type === "Image") {
    if (key === "z-index") {
      if (value === "") {
        value = 1;
      }
      obj.push({ [key]: value });
    }
    if (key === "position-absolute") {
      let totalAbsolutePosition = Object.keys(value).length;
      let absolutePosition = Object.keys(value);
      for (let ab = 0; ab < totalAbsolutePosition; ab++) {
        if (value[absolutePosition[ab]] !== "") {
          obj.push({
            [absolutePosition[ab]]: `${value[absolutePosition[ab]]}%`,
          });
        }
      }
    }
  }
  return obj;
};

const absoluteObject = (position, obj) => {
  let pst = ["bottom", "top", "right", "left"];
  for (let a = 0; a < pst.length; a++) {
    if (position[pst[a]]) {
      if (position[pst[a]].toString().trim() !== "") {
        obj.push({
          [pst[a]]: `${position[pst[a]]}%`,
        });
      } else {
        obj.push({
          [pst[a]]: `unset`,
        });
      }
    } else {
      obj.push({
        [pst[a]]: `unset`,
      });
    }
  }
  return obj;
};

const contentConvert = (oldcontent, filterFontFamily, isResponsive) => {
  let type = "Teks";
  let content = { ...oldcontent };
  let obj = [];
  let extract = extractObject(content);
  let key = extract.objKeys;
  let value = extract.objValues;
  let mediaQueri768 = {};
  let mediaQueri360 = {};
  let hover = [];
  let inner = [];
  let wrapper = [];
  let next = false;
  if (content) {
    type = content.type;
    let position = "static";
    if (content.position) {
      position = content.position;
    } else {
      position = "static";
    }
    let continueObj = false;
    for (let a = 0; a < key.length; a++) {
      if (value[a] !== undefined || value[a].toString().trim() !== "") {
        let isSupported = CSS.supports(key[a], value[a]);
        if (isSupported) {
          if (key[a] === "font-family") {
            value[a] = encryptSpace(value[a]);
            checkingFonts(content, filterFontFamily);
            obj.push({ "font-family": value[a] });
          } else {
            if (
              key[a] === "border-radius" ||
              key[a] === "border-top-left-radius" ||
              key[a] === "border-top-right-radius" ||
              key[a] === "border-bottom-left-radius" ||
              key[a] === "border-bottom-right-radius"
            ) {
            } else {
              if (key[a] === "background-color") {
                if (value[a] === TRANSCOLOR) {
                  obj.push({ [key[a]]: TRANSCOLORRGBA });
                } else {
                  obj.push({ [key[a]]: value[a] });
                }
              } else {
                obj.push({ [key[a]]: value[a] });
              }
            }
          }
        } else {
          if (key[a] === "media-queri768") {
            mediaQueri768 = value[a];
          } else if (key[a] === "media-queri360") {
            mediaQueri360 = value[a];
          } else {
            /**
             * invalid key and value
             * but we need so hard push to obj
             */
            unsupportedTag(obj, content, key[a], value[a]);
          }
        }
      }
      if (a === key.length - 1) {
        continueObj = true;
      }
    }
    if (continueObj) {
      if (mediaQueri768) {
        mediaQueri768 = {
          ...mediaQueri768,
          type: type,
          position: position,
        };
      }
      if (mediaQueri360) {
        mediaQueri360 = {
          ...mediaQueri360,
          type: type,
          position: position,
        };
      }
      if (type === "Button") {
        if (!content.border) {
          obj.push({
            border: "0",
          });
        }
        if (content.backgroundColor !== TRANSCOLOR) {
          hover.push({
            background: content.backgroundColor,
          });
        }
        if (content.fontFamily) {
          inner.push({
            "font-family": content.fontFamily,
          });
        }
        if (content.textAlign) {
          console.log("content.textAlign", content.textAlign);
          wrapper.push({
            "text-align": content.textAlign,
          });
        }
      }

      if (type === "Icon") {
        obj = removeProperty(obj, "background");
      }

      if (type === "Image") {
        wrapper.push({
          width: "fit-content",
        });
        if (content.textAlign === "center") {
          wrapper.push({
            margin: "0%20auto",
            "text-align": "center",
          });
        } else {
          wrapper.push({
            "text-align": content.textAlign,
          });
        }
        if (content.rotate) {
          obj.push({
            transform: `rotate(${content.rotate}deg)`,
          });
        }
        if (content.position === "absolute") {
          if (type === "Image") {
            if (content.positionAbsolute) {
              obj = absoluteObject(content.positionAbsolute, obj);
            }
            if (isResponsive !== undefined && !isResponsive) {
              if (mediaQueri768.positionAbsolute) {
                mediaQueri768 = absoluteObject(
                  mediaQueri768.positionAbsolute,
                  obj
                );
              }
              if (mediaQueri360.positionAbsolute) {
                mediaQueri360 = absoluteObject(
                  mediaQueri360.positionAbsolute,
                  obj
                );
              }
            }
          }
        }
      }
      if (type === "Timeline") {
        if (content.backgroundColor) {
          obj = removeProperty(obj, "background-color");
          inner.push({
            "background-color": content.backgroundColor,
          });
        }
      }
      if (type === "Bank") {
        if (content.marginLeft) {
          obj = removeProperty(obj, "margin-left");
        }
        if (content.marginRight) {
          obj = removeProperty(obj, "margin-right");
        }
      }
      obj = checkingBorderRadius(content, obj);
      next = true;
    }
  }
  if (next) {
    return {
      obj,
      mediaQueri768,
      mediaQueri360,
      hover,
      inner,
      wrapper,
    };
  }
};

const extractObject = (obj) => {
  let objValues = [];
  let objKeys = [];
  for (let [key, value] of Object.entries(obj)) {
    if (key) {
      key = key
        .replace(/([A-Z])/g, "-$1")
        .trim()
        .toLowerCase();
      objKeys.push(key);
      objValues.push(value);
    }
  }
  return {
    objValues,
    objKeys,
  };
};

const pushCSS = (className, cssCodes, obj, mode) => {
  let finalObj = {};
  let newObject = [];
  if (obj.length === 1) {
    /***
     * when obj length ==1
     * obj doesnt contain ;
     * ";" it is important for builder to check
     * so inject custom obj
     *
     */
    obj.push({
      custom: "auto",
    });
  }
  for (let i = 0; i < obj.length; i++) {
    Object.assign(finalObj, obj[i]);
  }

  newObject.push(
    `{${Object.entries(finalObj)
      .map(([k, v]) => `${k}:${v}`)
      .join(";")}}`
  );
  if (newObject[0] !== "{}") {
    if (mode && mode === "hover") {
      cssCodes = cssCodes + `${className}:hover ${newObject[0]}`;
      cssCodes = cssCodes + `${className}:active ${newObject[0]}`;
      cssCodes = cssCodes + `${className}:focus ${newObject[0]}`;
    } else if (mode && mode === "inner") {
      cssCodes = cssCodes + `${className}-inner ${newObject[0]}`;
    } else if (mode && mode === "wrapper") {
      cssCodes = cssCodes + `${className}-wrapper ${newObject[0]}`;
    } else {
      cssCodes = cssCodes + `${className} ${newObject[0]}`;
    }
  }
  return cssCodes;
};

const pushMediaQuery = (maxWidth, obj, slideQuery, colomQuery) => {
  let mQuery = "";
  if (maxWidth === "500px") {
    mQuery = `@media%20screen%20and%20(max-width:%20700px){`;
  } else {
    mQuery = `@media%20screen%20and%20(min-width:%20701px)and%20(max-width:%20${maxWidth}){`;
  }
  for (let a = 0; a < obj.length; a++) {
    let tag = obj[a].classname.tag;
    let newObject = { ...obj[a].classname };
    let resnya = contentConvert(newObject, []);
    if (resnya) {
      if (resnya.obj.length > 0) {
        mQuery = pushCSS(tag, mQuery, resnya.obj);
      }
    }
  }
  mQuery += slideQuery + colomQuery;
  mQuery += "}";
  return mQuery;
};

const convertCSS = (tempCssSlides, isResponsive) => {
  let mediaQueryTablet = [];
  let mediaQueryPhone = [];
  let slideQueryPhone = [];
  let slideQueryTablet = [];
  let colomQueryPhone = [];
  let colomQueryTablet = [];
  let filterFontFamily = [];
  let cssCodes = "";
  let cssCodes500 = "";
  let cssCodes900 = "";
  let queryPhone = "";
  let queryTablet = "";
  let continueNext = false;
  let slideTag = `.slide-`;
  for (let a = 0; a < tempCssSlides.length; a++) {
    //slides
    let resnya = slideConvert(tempCssSlides[a]);
    cssCodes = pushCSS(`${slideTag}${a}`, cssCodes, resnya.obj);
    cssCodes = pushCSS(`${slideTag}inner-${a}`, cssCodes, resnya.objInner);
    if (resnya.obj360) {
      mediaQueryPhone.push({
        classname: { ...resnya.obj360, tag: `${slideTag}${a}` },
      });
    }
    if (resnya.objInner360) {
      mediaQueryPhone.push({
        classname: { ...resnya.objInner360, tag: `${slideTag}inner-${a}` },
      });
    }
    if (resnya.obj768) {
      mediaQueryTablet.push({
        classname: { ...resnya.obj768, tag: `${slideTag}${a}` },
      });
    }
    if (resnya.objInner768) {
      mediaQueryTablet.push({
        classname: { ...resnya.objInner768, tag: `${slideTag}inner-${a}` },
      });
    }

    if (tempCssSlides[a].col) {
      let xtractCSS = colomConvert(
        cssCodes,
        cssCodes500,
        cssCodes900,
        tempCssSlides[a].col,
        `${slideTag}${a}-col-`,
        filterFontFamily,
        mediaQueryPhone,
        mediaQueryTablet,
        colomQueryPhone,
        colomQueryTablet,
        isResponsive
      );
      cssCodes = xtractCSS.cssCodes;
      cssCodes500 = xtractCSS.cssCodes500;
      cssCodes900 = xtractCSS.cssCodes900;
    }
    if (tempCssSlides[a].mediaQueri768) {
      let res768 = slideConvert(tempCssSlides[a].mediaQueri768);
      if (a === 0) {
        res768.obj.width = "100%";
      }
      slideQueryTablet = pushCSS(
        `${slideTag}${a}`,
        slideQueryTablet,
        res768.obj
      );
      slideQueryTablet = pushCSS(
        `${slideTag}inner-${a}`,
        slideQueryTablet,
        res768.objInner
      );
      if (!isResponsive) {
        cssCodes = pushCSS(`${slideTag}${a}`, cssCodes, resnya.obj768);
        cssCodes = pushCSS(
          `${slideTag}inner-${a}`,
          cssCodes,
          resnya.objInner768
        );
      }
    }

    if (tempCssSlides[a].mediaQueri360) {
      let res360 = slideConvert(tempCssSlides[a].mediaQueri360);
      if (a === 0) {
        res360.obj.width = "100%";
      }
      slideQueryPhone = pushCSS(`${slideTag}${a}`, slideQueryPhone, res360.obj);
      slideQueryPhone = pushCSS(
        `${slideTag}inner-${a}`,
        slideQueryPhone,
        res360.objInner
      );
      if (!isResponsive) {
        cssCodes = pushCSS(`${slideTag}${a}`, cssCodes, resnya.obj360);
        cssCodes = pushCSS(
          `${slideTag}inner-${a}`,
          cssCodes,
          resnya.objInner360
        );
      }
    }

    if (a === tempCssSlides.length - 1) {
      continueNext = true;
    }
  }
  if (mediaQueryTablet.length > 0) {
    let tablet = pushMediaQuery(
      "900px",
      mediaQueryTablet,
      slideQueryTablet,
      cssCodes900
    );
    queryTablet += tablet;
  }
  if (mediaQueryPhone.length > 0) {
    let phone = pushMediaQuery(
      "500px",
      mediaQueryPhone,
      slideQueryPhone,
      cssCodes500
    );
    queryPhone += phone;
  }
  cssCodes += queryPhone + queryTablet;
  cssCodes = cssCodes.replace(/\s/g, "");
  return {
    fontFamily: filterFontFamily,
    css: cssCodes,
    errorMessage: errorMessage,
  };
};
export default convertCSS;
