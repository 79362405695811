import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import momentService from "../../../helpers/momentHelper";
import Form from "react-bootstrap/Form";
const columns = (row, detail, query, actionCB) => {
  console.log("userss", row);

  const sumbitDetail = (row, action) => {
    detail(row, action);
  };
  const share = (row, action) => {
    detail(row, action);
  };
  const changeStatus = (id, action) => {
    alert(id);
    detail(id, action);
  };
  return [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: "40px",
    },
    {
      name: "",
      cell: (row, index) => {
        return (
          <>
            <input
              type="checkbox"
              value={row.app_id._id}
              checked={row.app_id.status == "active" ? true : false}
              onClick={($e) => {
                detail($e.target.value, "status");
              }}
            />
          </>
        );
      },
      width: "40px",
    },
    {
      name: "Subs",
      cell: (row) => row.app_id.type,
      width: "90px",
    },
    {
      name: "Email",
      cell: (row) => row.email,
    },
    {
      name: "Nama",
      cell: (row) => row.name,
    },
    {
      name: "Whatsapp",
      cell: (row) => row.whatsapp,
    },
    {
      name: "Tanggal Register",
      cell: (row) => momentService(row.createdAt),
      width: "150px",
    },
    {
      name: "Next Payment",
      cell: (row) => momentService(row.app_id.active),
      width: "150px",
    },
    {
      name: "Referal",
      cell: (row, index) => {
        return (
          <>
            {row.affiliate}
            <i
              className="fa fa-edit"
              onClick={() => actionCB("referal", row)}
            ></i>
          </>
        );
      },
      width: "120px",
    },
    {
      name: "Status",
      cell: (row, index) => {
        return (
          <>
            <i
              className="fa fa-envelope mr-3"
              onClick={() => actionCB("message", row)}
            ></i>
            <i
              className="fa fa-user mr-3"
              onClick={() => actionCB("user", row)}
            ></i>
            <i
              className="fa fa-lock mr-3"
              onClick={() => actionCB("password", row)}
            ></i>
            <i
              className="fa fa-check"
              onClick={() => actionCB("activation", row)}
            ></i>
          </>
        );
      },
    },
  ];
};

export default columns;
