import axiosInstance from "../helpers/axiosHelper";

export const userLoginServices = (email, password) => {
  return axiosInstance.post(`/api/v1/auth/login`, { email, password });
};

export const userLogoutServices = () => {
  return axiosInstance.post(`/api/v1/auth/logout`);
};

const path = "/api/v1/admin/manage-users";
export const getUsers = (pageSize, page, q) => {
  return axiosInstance.get(`${path}?pageSize=${pageSize}&page=${page}${q}`);
};

export const getUserDetail = (id_landingpage) => {
  return axiosInstance.get(`${path}/${id_landingpage}`);
};

export const updateUserService = (id, data) => {
  return axiosInstance.put(`${path}/${id}`, data);
};
export const updateUserPasswordService = (id, password) => {
  return axiosInstance.put(`${path}/${id}/password`, {
    password: password,
    confirmationPassword: password,
  });
};
export const createUser = (data) => {
  return axiosInstance.post(`${path}`, data);
};
export const deleteUser = (id) => {
  return axiosInstance.delete(`${path}/${id}`);
};

export const changeStatus = (id) => {
  return axiosInstance.put(`/api/v1/admin/app/status/${id}`);
};
export const subscription = (id, data) => {
  return axiosInstance.post(`/api/v1/admin/subscription/${id}`, data);
};
export const referalService = (data) => {
  return axiosInstance.post(`/api/v1/admin/referal`, data);
};
